import {
  Spinner,
  MessageBar,
  MessageBarType,
  Dialog,
  DialogFooter,
  DialogType,
  DatePicker,
  DefaultButton,
  PrimaryButton,
  Checkbox,
} from '@fluentui/react';
import {useEffect, useState} from 'react';
import commonStyles from '../../common/commonStyles.module.scss';
import {getTimezoneDate} from '../../common/dateFormatting';
import {Subscription} from '../../Swagger/api';
import {getApis} from '../../webservices';
import styles from './subscriptions.module.scss';

interface IExtendAssuranceSubscription {
  onDone: () => void;
  onCancel: () => void;
  subscription: Subscription;
}

export const ExtendAssuranceSubscription = (
  props: IExtendAssuranceSubscription
) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [expiration, setExpiration] = useState<Date>();
  const [hasEndDate, setHasEndDate] = useState(false);
  const today = new Date();
  const initialEndDate = new Date(
    today.getFullYear() + 1,
    today.getMonth(),
    today.getDate()
  );

  useEffect(() => {
    const endDate = props.subscription.expiration
      ? new Date(props.subscription.expiration.toString())
      : new Date();
    const hasEndDateInit =
      endDate.toLocaleDateString() !==
      new Date(9999, 11, 31).toLocaleDateString();
    setHasEndDate(hasEndDateInit);
    setExpiration(hasEndDateInit ? endDate : undefined);
  }, [props.subscription.expiration]);

  const onExtendSubscription = async () => {
    setLoading(true);
    setHasError(false);

    try {
      const endDate = hasEndDate
        ? expiration
          ? getTimezoneDate(expiration)
          : undefined
        : new Date(9999, 11, 31, 23, 59, 59, 999);
      const {subscriptionClient} = await getApis();
      await subscriptionClient.extendSubscription(
        props.subscription.id,
        endDate
      );
      props.onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        subText: 'Please set the end date of the software assurance contract:',
        title: 'Change contract end date',
        type: DialogType.normal,
      }}
      modalProps={{
        isBlocking: true,
      }}
      minWidth="40em"
      onDismiss={props.onCancel}
    >
      <Checkbox
        label="Contract without end date"
        className={styles.checkbox}
        checked={!hasEndDate}
        onChange={(_event, checked) => {
          setHasEndDate(!checked as boolean);
          setExpiration(checked ? undefined : initialEndDate);
        }}
      />
      <DatePicker
        label="End date"
        placeholder="Select a date..."
        allowTextInput={true}
        disabled={!hasEndDate}
        value={expiration}
        onSelectDate={(newValue) => setExpiration(newValue || new Date())}
        ariaLabel="Select a date"
      />
      <DialogFooter>
        <section className={commonStyles.dialogMessages}>
          {loading ? (
            <Spinner
              className={commonStyles.spinner}
              label="Please wait"
              ariaLive="assertive"
              labelPosition="right"
            />
          ) : null}
          {hasError ? (
            <MessageBar
              className={commonStyles.messageBar}
              messageBarType={MessageBarType.error}
              isMultiline={true}
              dismissButtonAriaLabel="Close"
            >
              Something went wrong. Please try again or contact an
              Administrator.
            </MessageBar>
          ) : null}
        </section>
        <PrimaryButton onClick={onExtendSubscription} text="Save" />
        <DefaultButton onClick={props.onCancel} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};
