import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  MessageBar,
  MessageBarType,
  SearchBox,
  Spinner,
  TextField,
} from '@fluentui/react';
import {useEffect, useState} from 'react';
import commonStyles from '../../../common/commonStyles.module.scss';
import {RegisteredUser, Subscription} from '../../../Swagger/api';
import {getApis} from '../../../webservices';
import styles from './registeredUsers.module.scss';

interface IRegisteredUsers {
  subscription: Subscription;
  onReload: () => void;
  onDone: () => void;
}

export const RegisteredUsers = ({
  subscription,
  onReload,
  onDone,
}: IRegisteredUsers) => {
  const [users, setUsers] = useState<RegisteredUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    const filterUsers = () => {
      return (subscription.registeredUsers || []).filter(
        (x) =>
          x.emailAddress && x.emailAddress.toLowerCase().indexOf(query) !== -1
      );
    };

    setLoading(true);
    setUsers(filterUsers());
    setLoading(false);
  }, [subscription, query]);

  const deleteUser = async (userId: number) => {
    setLoading(true);
    setHasError(false);
    try {
      const {subscriptionClient} = await getApis();
      await subscriptionClient.removeUser(userId);
    } catch {
      setHasError(true);
    }
    onReload();
    setLoading(false);
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: 'Registered users',
        type: DialogType.normal,
      }}
      modalProps={{
        isBlocking: true,
      }}
      minWidth="40em"
      onDismiss={onDone}
    >
      <section>
        <SearchBox
          className={styles.searchBox}
          onChange={(_event, value) => setQuery(value || '')}
          placeholder="search for users"
        />
      </section>
      {users.length ? (
        <section className={styles.items}>
          {users.slice(0, 20).map((user) => (
            <article className={styles.item} key={user.id}>
              <TextField
                className={styles.text}
                readOnly
                value={user.emailAddress ?? ''}
              />
              <IconButton
                iconProps={{iconName: 'Delete'}}
                onClick={() => deleteUser(user.id!)}
              />
            </article>
          ))}
        </section>
      ) : (
        <MessageBar>
          No users have been found (total users:{' '}
          {(subscription.registeredUsers || []).length})
        </MessageBar>
      )}
      {users.length > 24 ? (
        <MessageBar>
          Showing the top 25 Users (total users:{' '}
          {(subscription.registeredUsers || []).length})
        </MessageBar>
      ) : null}
      <DialogFooter>
        <section className={commonStyles.dialogMessages}>
          {loading ? (
            <Spinner
              className={commonStyles.spinner}
              label="Please wait"
              ariaLive="assertive"
              labelPosition="right"
            />
          ) : null}
          {hasError ? (
            <MessageBar
              className={commonStyles.messageBar}
              messageBarType={MessageBarType.error}
              isMultiline={true}
              dismissButtonAriaLabel="Close"
            >
              Something went wrong. Please try again or contact an
              Administrator.
            </MessageBar>
          ) : null}
        </section>
        <DefaultButton onClick={onDone} text="Close" />
      </DialogFooter>
    </Dialog>
  );
};
