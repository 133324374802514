import {
  DefaultButton,
  PrimaryButton,
  Checkbox,
  DatePicker,
  Dropdown,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  Spinner,
  TextField,
} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {getTimezoneDate} from '../../common/dateFormatting';
import {Product, Subscription, SubscriptionType} from '../../Swagger/api';
import {getApis} from '../../webservices';
import styles from './subscriptions.module.scss';
import commonStyles from '../../common/commonStyles.module.scss';

interface ISubscriptionAdd {
  onDone: () => void;
  onCancel: () => void;
  clientId: number;
}

export default function SubscriptionAdd(props: ISubscriptionAdd) {
  const [products, setProducts] = useState<Product[]>([]);
  const [product, setProduct] = useState<Product>();
  const [allowedSubscriptionTypes, setAllowedSubscriptionTypes] = useState<
    IDropdownOption[]
  >([]);
  const [subscriptionType, setSubscriptionType] = useState(0);
  const [productId, setProductId] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [expirationDate, setExpirationDate] = useState<Date>();
  const [maxUsers, setMaxUsers] = useState('');
  const [hasEndDate, setHasEndDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const loadProducts = async () => {
    const {productClient} = await getApis();
    const items: Product[] = await productClient.getProducts();
    setProducts(items);
  };

  useEffect(() => {
    loadProducts();
  }, []);

  const setProductTypes = (selectedProductId: number) => {
    const selectedProduct: Product = products.filter(
      (_x) => _x.id === selectedProductId
    )[0];
    setProduct(selectedProduct);
    setAllowedSubscriptionTypes(getSubscriptionTypesByProduct(selectedProduct));
  };

  const isAllowedType = (item: SubscriptionType, value: SubscriptionType) => {
    return (value & item) !== 0;
  };

  const getSubscriptionTypesByProduct = (selectedProduct: Product) => {
    const items: IDropdownOption[] = [];
    if (
      selectedProduct &&
      selectedProduct.allowedSubscriptionTypes !== undefined
    ) {
      if (
        isAllowedType(
          selectedProduct.allowedSubscriptionTypes,
          SubscriptionType.Demo
        )
      ) {
        items.push({key: 1, text: 'Demo'});
      }
      if (
        isAllowedType(
          selectedProduct.allowedSubscriptionTypes,
          SubscriptionType.PerUser
        )
      ) {
        items.push({key: 2, text: 'Per user'});
      }
      if (
        isAllowedType(
          selectedProduct.allowedSubscriptionTypes,
          SubscriptionType.FlatRate
        )
      ) {
        items.push({key: 4, text: 'Flatrate'});
      }
      if (
        isAllowedType(
          selectedProduct.allowedSubscriptionTypes,
          SubscriptionType.PerUserSharePoint
        )
      ) {
        items.push({key: 8, text: 'Per user SharePoint'});
      }
      if (
        isAllowedType(
          selectedProduct.allowedSubscriptionTypes,
          SubscriptionType.FlatrateSharePoint
        )
      ) {
        items.push({key: 16, text: 'Flatrate SharePoint'});
      }
      if (
        isAllowedType(
          selectedProduct.allowedSubscriptionTypes,
          SubscriptionType.SoftwareAssurance
        )
      ) {
        items.push({key: 32, text: 'Software Assurance'});
      }
    }

    return items;
  };

  const onSave = async () => {
    setLoading(true);
    setHasError(false);

    const item: Subscription = {
      clientId: props.clientId,
      expiration:
        subscriptionType === 32 && !hasEndDate
          ? new Date(9999, 11, 31, 23, 59, 59, 999)
          : expirationDate
          ? getTimezoneDate(expirationDate)
          : undefined,
      maxUsers: Number(maxUsers),
      productId,
      start: getTimezoneDate(startDate),
      type: subscriptionType,
      id: 0,
      remainingDemoPermissions: -1,
    };

    try {
      const {subscriptionClient} = await getApis();
      await subscriptionClient.addSubscription(item);
      props.onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Panel
      isOpen={true}
      type={PanelType.smallFixedFar}
      onDismiss={() => props.onCancel()}
      headerText="Add a new Subscription"
      closeButtonAriaLabel="Close"
      onRenderFooterContent={() => {
        return (
          <React.Fragment>
            <section className={commonStyles.panelButtons}>
              <PrimaryButton
                className={commonStyles.primary}
                onClick={onSave}
                disabled={loading}
              >
                Save
              </PrimaryButton>
              <DefaultButton onClick={props.onCancel}>Cancel</DefaultButton>
            </section>

            <section className={commonStyles.panelMessages}>
              {loading ? (
                <Spinner
                  label="Please wait"
                  ariaLive="assertive"
                  labelPosition="right"
                />
              ) : null}
              {hasError ? (
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={true}
                  dismissButtonAriaLabel="Close"
                >
                  Something went wrong. Please try again or contact an
                  Administrator.
                </MessageBar>
              ) : null}
            </section>
          </React.Fragment>
        );
      }}
    >
      <Dropdown
        label="Product"
        onChange={(_event, newValue) => {
          setProductTypes(newValue && newValue.key ? Number(newValue.key) : -1);
          setProductId(newValue && newValue.key ? Number(newValue.key) : -1);
        }}
        options={products.map((x) => {
          return {key: x.id || -1, text: x.name || ''};
        })}
      />
      <Dropdown
        disabled={!product || !product.id}
        label="Type"
        onChange={(_event, newValue) => {
          setSubscriptionType(
            newValue && newValue.key ? Number(newValue.key) : -1
          );
        }}
        options={allowedSubscriptionTypes}
      />
      <DatePicker
        label="Start date"
        allowTextInput={true}
        placeholder="Select a date..."
        value={startDate}
        onSelectDate={(newValue) => setStartDate(newValue || new Date())}
        ariaLabel="Select a date"
      />
      <Checkbox
        label="Contract without end date"
        disabled={subscriptionType !== 32}
        className={styles.checkbox}
        checked={subscriptionType === 32 ? !hasEndDate : false}
        onChange={(_event, checked) => setHasEndDate(!checked as boolean)}
      />
      <DatePicker
        label={
          subscriptionType === 32 ? 'Contract end date' : 'Expiration date'
        }
        allowTextInput={true}
        placeholder="Select a date..."
        disabled={
          subscriptionType === 4 ||
          subscriptionType === 16 ||
          (subscriptionType === 32 && !hasEndDate)
        }
        value={expirationDate}
        onSelectDate={(newValue) => setExpirationDate(newValue || undefined)}
        ariaLabel="Select a date"
      />

      <TextField
        label="Max users"
        value={maxUsers}
        onChange={(_event, newValue) => setMaxUsers(newValue || '')}
        disabled={
          subscriptionType === 1 ||
          subscriptionType === 4 ||
          subscriptionType === 16
        }
      />
    </Panel>
  );
}
