import {Dialog, DefaultButton, Stack, Text} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  AuditClient,
  AuditViewModel,
  Client,
  ClientClient,
} from '../../../Swagger/api';
import {getApis} from '../../../webservices';
import AuditList from '../list/auditsList';
import {paths} from '../../../routing/routes';
import styles from './auditsDialog.module.scss';

interface IAuditDialog {
  clientId: number;
  onClose: () => void;
}

export default function AuditDialog(props: IAuditDialog) {
  const [audits, setAudits] = useState<AuditViewModel[]>([]);
  const [client, setClient] = useState<Client>();
  const [isLoadComplete, setIsLoadComplete] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const {auditClient, clientClient} = await getApis();
      await Promise.all([getAudits(auditClient), getClient(clientClient)]);
    } catch (error) {
      console.error(error);
      setHasError(true);
    }

    setIsLoadComplete(true);
  }

  async function getAudits(auditClient: AuditClient) {
    const filter = {
      create: true,
      modify: true,
      delete: true,
      to: undefined,
      from: undefined,
      clients: [props.clientId],
    };
    const auditResponse = await auditClient.getAudits(filter);
    // select up to 10 audits (per default sorted by date desc)
    const maxIndexToSlice =
      auditResponse.length > 10 ? 10 : auditResponse.length;
    const portionOfAudits = auditResponse.slice(0, maxIndexToSlice);
    setAudits(portionOfAudits);
  }

  async function getClient(clientClient: ClientClient) {
    const clientResponse = await clientClient.getClient(props.clientId);
    setClient(clientResponse);
  }

  return (
    <Dialog
      onDismiss={props.onClose}
      modalProps={{isBlocking: true}}
      title={`Audits for client ${client?.companyName}`}
      hidden={false}
      minWidth="60em"
    >
      <section>
        <AuditList
          audits={audits}
          hasError={hasError}
          isLoadComplete={isLoadComplete}
          showClientsColumn={false}
        ></AuditList>
      </section>
      <Stack
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        className={styles.dialogFooter}
      >
        <Stack.Item>
          <Text>
            These are the last 10 changes made for this client. To view more
            information please visit the{' '}
            <Link to={paths.audit.root}>audits page</Link>
          </Text>
        </Stack.Item>
        <DefaultButton onClick={props.onClose}>Close</DefaultButton>
      </Stack>
    </Dialog>
  );
}
