export const formatDateGerman = (date: Date) => {
  const utcDate = new Date(date);
  const localDate = new Date(utcDate.toUTCString());

  if (
    localDate.toDateString() ===
    new Date(9999, 11, 31, 23, 59, 59).toDateString()
  ) {
    return 'Contract without end date';
  }

  return localDate.toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const formatDateTimeGerman = (date: Date) => {
  const utcDate = new Date(date);
  const localDate = new Date(utcDate.toUTCString());
  return localDate.toLocaleTimeString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const getTimezoneDate = (date: Date) => {
  const offset = date.getTimezoneOffset() / 60;
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours() - offset
  );
};
