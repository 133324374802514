import {DefaultButton, MessageBar} from '@fluentui/react';
import {useState} from 'react';
import {ProductSetting} from '../../../Swagger/api';
import styles from '../../subscriptions/subscriptions.module.scss';
import {ProductSettingRemove} from '../productSettingRemove';

interface IUnsupportedProduct {
  onDone: () => void;
  settings: ProductSetting;
}

export const UnsupportedProduct = (props: IUnsupportedProduct) => {
  const [productRemovePanelOpen, setProductRemovePanelOpen] = useState(false);

  return (
    <article className={`${styles.subscriptionContainer} subscription-item`}>
      <section className={styles.container}>
        <MessageBar>
          The product {props.settings.product!.name} is not supported
        </MessageBar>
      </section>
      <section className={styles.buttonContainer}>
        <article className={`${styles.leftContainer} ${styles.buttons}`} />
        <article className={`${styles.rightContainer} ${styles.buttons}`}>
          <DefaultButton
            className={styles.remove}
            onClick={() => setProductRemovePanelOpen(true)}
          >
            Delete settings
          </DefaultButton>
        </article>
      </section>
      {productRemovePanelOpen ? (
        <ProductSettingRemove
          onCancel={() => setProductRemovePanelOpen(false)}
          productSetting={props.settings}
          onDone={() => {
            setProductRemovePanelOpen(false);
            props.onDone();
          }}
        />
      ) : null}
    </article>
  );
};
