import * as Msal from '@azure/msal-browser';
import {
  AuditClient,
  ClientClient,
  ProductClient,
  ProductSettingClient,
  SubscriptionClient,
} from './Swagger/api';

const basePath =
  process.env.NODE_ENV === 'production'
    ? window.location.origin
    : 'https://localhost:5001';
const clientId = process.env.REACT_APP_CLIENT_ID!;
const redirectUrl = `${process.env.REACT_APP_REDIRECT_URL}`;
const loginRequest = {
  scopes: [process.env.REACT_APP_ADMIN_SCOPE as string],
};
const msalConfig: Msal.Configuration = {
  auth: {
    clientId,
    redirectUri: redirectUrl,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`
  },
  cache: {
    cacheLocation: 'localStorage'
  }
};

export const msalInstance = new Msal.PublicClientApplication(msalConfig);

export async function handleRedirectLogin() {
  try {
    await msalInstance.loginRedirect(loginRequest);
  } catch (error) {
    console.error(error);
  }
}

export async function handleRedirectLogout() {
  try {
    await msalInstance.logoutRedirect();
  } catch (error) {
    console.error(error);
  }
}

function addHeader(accessToken: string) {
  return function getFetchWithAuthHeader(url: RequestInfo, init: RequestInit) {
    (init.headers as any)['Authorization'] = `Bearer ${accessToken}`;
    return fetch(url, init);
  };
}

export async function getApis() {
  let response = {accessToken: ''} as Msal.AuthenticationResult;

  // disable auth for testing via cypress
  if (!process.env.REACT_APP_IS_TEST_ENV) {
    // getActiveAccount returns undefined, even if we are authenticated. Because of that we use the first account
    const account = msalInstance.getAllAccounts()?.[0];
    if (!account) {
      throw new Error('no account found');
    }

    response = await msalInstance.acquireTokenSilent({
      account,
      scopes: loginRequest.scopes,
    });
  }

  const fetch = addHeader(response.accessToken);

  const subscriptionClient = new SubscriptionClient(basePath, {
    fetch,
  });
  const clientClient = new ClientClient(basePath, {
    fetch,
  });
  const productClient = new ProductClient(basePath, {
    fetch,
  });
  const productSettingClient = new ProductSettingClient(basePath, {
    fetch,
  });

  const auditClient = new AuditClient(basePath, {fetch});

  return {
    subscriptionClient,
    clientClient,
    productClient,
    productSettingClient,
    auditClient,
  };
}
