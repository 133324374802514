import {
  DetailsList,
  SelectionMode,
  MessageBar,
  MessageBarType,
  SearchBox,
  CommandBar,
} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {Client} from '../../Swagger/api';
import {getApis} from '../../webservices';
import {history} from '../../routing/routes';
import {ClientAdd} from './clientAdd';
import {ClientEdit} from './clientEdit';
import {ClientRemove} from './clientRemove';
import styles from './clients.module.scss';

export const Clients = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [filteredClients, setFilteredClients] = useState<Client[]>([]);
  const [selectedClient, setSelectedClient] = useState<Client>();
  const [clientAddPanelOpen, setClientAddPanelOpen] = useState(false);
  const [clientEditPanelOpen, setClientEditPanelOpen] = useState(false);
  const [clientRemovePanelOpen, setClientRemovePanelOpen] = useState(false);
  const [hasError, setError] = useState(false);
  const [query, setQuery] = useState('');
  const [sortDesc, setSortDesc] = useState(false);
  const emptyClient: Client = {id: 0};

  useEffect(() => {
    loadClients();
  }, []);

  useEffect(() => {
    const copy = [...clients];
    const result = copy.filter(
      (x) => x.companyName?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );

    const sortClients = (data: Client[]) => {
      const sorted = data.sort((a, b) =>
        (a.companyName?.toLowerCase() || '') <
        (b.companyName?.toLowerCase() || '')
          ? -1
          : 1
      );

      if (sortDesc) {
        sorted.reverse();
      }

      return sorted;
    };

    const sortedResult = sortClients(result);
    setFilteredClients(sortedResult);
  }, [clients, query, sortDesc]);

  const loadClients = async () => {
    try {
      const {clientClient} = await getApis();
      const items: Client[] = await clientClient.getClients();
      setClients(items);
    } catch {
      setError(true);
    }
  };

  return (
    <React.Fragment>
      <h1>Manage Clients</h1>
      <CommandBar
        items={[
          {
            iconProps: {iconName: 'AddFriend'},
            key: 'add client',
            onClick: () => setClientAddPanelOpen(true),
            text: 'Add a new client',
          },
          {
            disabled: !selectedClient,
            iconProps: {iconName: 'Edit'},
            key: 'edit client',
            onClick: () => setClientEditPanelOpen(true),
            text: 'Edit client',
          },
          {
            disabled: !selectedClient,
            iconProps: {iconName: 'UserRemove'},
            key: 'delete client',
            onClick: () => setClientRemovePanelOpen(true),
            text: 'Delete client',
          },
          {
            disabled: !selectedClient,
            iconProps: {iconName: 'UserFollowed'},
            key: 'subscriptions',
            onClick: () => history.push(`/subscriptions/${selectedClient!.id}`),
            text: 'Open client subscriptions',
          },
          {
            disabled: !selectedClient,
            iconProps: {iconName: 'Settings'},
            key: 'product settings',
            onClick: () =>
              history.push(`/productSettings/${selectedClient!.id}`),
            text: 'Open product settings',
          },
        ]}
      />
      {hasError ? (
        <MessageBar messageBarType={MessageBarType.error}>
          Could not load clients.
        </MessageBar>
      ) : null}
      <SearchBox
        value={query}
        onChange={(_, value) => setQuery(value || '')}
        className={styles.searchBox}
      />
      <DetailsList
        columns={[
          {
            key: 'name',
            name: 'Name',
            fieldName: 'companyName',
            minWidth: 100,
            isSorted: true,
            isSortedDescending: sortDesc,
            onColumnClick: () => setSortDesc(!sortDesc),
          },
        ]}
        items={filteredClients.map((x) => {
          return {key: x.id, companyName: x.companyName, id: x.id};
        })}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick={true}
        onActiveItemChanged={(item) => setSelectedClient(item)}
      />
      {clientAddPanelOpen ? (
        <ClientAdd
          onCancel={() => setClientAddPanelOpen(false)}
          onDone={() => {
            loadClients();
            setClientAddPanelOpen(false);
          }}
        />
      ) : null}
      {clientEditPanelOpen ? (
        <ClientEdit
          onCancel={() => setClientEditPanelOpen(false)}
          client={selectedClient || emptyClient}
          onDone={() => {
            loadClients();
            setClientEditPanelOpen(false);
          }}
        />
      ) : null}
      {clientRemovePanelOpen ? (
        <ClientRemove
          onCancel={() => setClientRemovePanelOpen(false)}
          client={selectedClient || emptyClient}
          onDone={() => {
            loadClients();
            setClientRemovePanelOpen(false);
          }}
        />
      ) : null}
    </React.Fragment>
  );
};
