import {
  DefaultButton,
  Icon,
  Callout,
  Link,
  TextField,
  Text,
} from '@fluentui/react';
import React, {useState} from 'react';
import {copyTextToClipboard} from '../../common/copyText';
import {formatDateGerman} from '../../common/dateFormatting';
import {SubscriptionViewModel} from '../../Swagger/api';
import styles from './subscriptions.module.scss';
import {CancelSubscription} from './cancelSubscription';
import {LicenseDialog} from './licenseDialog';
import {ManageDomains} from './manageDomains/manageDomains';
import SerialKeysDialog from './serialKeysDialog';

interface IFlatrateSubscription {
  subscription: SubscriptionViewModel;
  onReload: () => void;
}

export const FlatrateSubscription = (props: IFlatrateSubscription) => {
  const [subscriptionManageDomainsOpen, setSubscriptionManageDomainsOpen] =
    useState(false);
  const [removeSubscriptionOpen, setRemoveSubscriptionOpen] = useState(false);
  const [copySuccessfulOpen, setCopySuccessfulOpen] = useState(false);
  const [copyFailedOpen, setCopyFailedOpen] = useState(false);
  const [infoTextOpen, setInfoTextOpen] = useState(false);
  const [isSerialKeyDialogOpen, setIsSerialKeyDialogOpen] = useState(false);

  const copyToClipboard = async () => {
    if (copyTextToClipboard(props.subscription.serialKey || '')) {
      setCopySuccessfulOpen(true);
    } else {
      setCopyFailedOpen(true);
    }
  };

  const _buttonELement = React.createRef<HTMLDivElement>();

  return (
    <article
      className={`${styles.subscriptionContainer} flatrate subscription-item`}
    >
      <section className={styles.title}>
        <strong>
          {props.subscription.product ? props.subscription.product.name : ''}
        </strong>{' '}
        - Flatrate subscription
        <Icon className={styles.icon} iconName="Airplane" />
      </section>
      <section className={styles.container}>
        <TextField
          label="Date started"
          readOnly
          value={
            props.subscription.start
              ? formatDateGerman(props.subscription.start)
              : ''
          }
        />
      </section>
      <section className={styles.container}>
        <TextField
          label="Serial key"
          readOnly
          value={props.subscription.serialKey || '0'}
        />
        <div className={styles.copy} ref={_buttonELement}>
          <DefaultButton onClick={() => copyToClipboard()}>Copy</DefaultButton>
          <Text className={styles.expirationDate}>
            (Expiration date:{' '}
            {props.subscription.serialKeyExpirationDate
              ? formatDateGerman(props.subscription.serialKeyExpirationDate)
              : 'Subscription has no expiration date'}
            )
          </Text>
        </div>
      </section>
      {copyFailedOpen ? (
        <section className={styles.container}>
          <Callout
            role="alertdialog"
            gapSpace={0}
            target={_buttonELement}
            setInitialFocus={true}
            hidden={false}
            onDismiss={() => setCopyFailedOpen(false)}
          >
            <div className={styles.callout}>
              Could not copy the serial key to the clipboard. Please try another
              browser.
            </div>
          </Callout>
        </section>
      ) : null}
      {copySuccessfulOpen ? (
        <section className={styles.container}>
          <Callout
            role="alertdialog"
            gapSpace={0}
            target={_buttonELement}
            setInitialFocus={true}
            hidden={false}
            onDismiss={() => setCopySuccessfulOpen(false)}
          >
            <div className={styles.callout}>
              Successfully copied the serial key to the clipboard.
            </div>
          </Callout>
        </section>
      ) : null}
      <section className={styles.container}>
        <span>
          *the serial key will be generated, when domains are updated.{' '}
          <Link onClick={() => setInfoTextOpen(true)}>
            See how to setup the automated offline registry entries.
          </Link>
        </span>
      </section>
      <section className={styles.buttonContainer}>
        <article className={`${styles.leftContainer} ${styles.buttons}`}>
          <DefaultButton onClick={() => setSubscriptionManageDomainsOpen(true)}>
            Manage domains
          </DefaultButton>
          <DefaultButton onClick={() => setIsSerialKeyDialogOpen(true)}>
            View serial keys
          </DefaultButton>
        </article>
        <article className={`${styles.rightContainer} ${styles.buttons}`}>
          <DefaultButton
            className={styles.remove}
            onClick={() => setRemoveSubscriptionOpen(true)}
          >
            Cancel subscription
          </DefaultButton>
        </article>
      </section>
      {subscriptionManageDomainsOpen ? (
        <ManageDomains
          onCancel={() => setSubscriptionManageDomainsOpen(false)}
          onDone={() => {
            setSubscriptionManageDomainsOpen(false);
            props.onReload();
          }}
          subscription={props.subscription}
        />
      ) : null}
      {removeSubscriptionOpen ? (
        <CancelSubscription
          onCancel={() => setRemoveSubscriptionOpen(false)}
          onDone={() => {
            setRemoveSubscriptionOpen(false);
            props.onReload();
          }}
          subscription={props.subscription}
        />
      ) : null}
      {infoTextOpen ? (
        <LicenseDialog onClose={() => setInfoTextOpen(false)} />
      ) : null}
      {props.subscription.serialKey && isSerialKeyDialogOpen && (
        <SerialKeysDialog
          activeSerialKey={props.subscription.serialKey ?? ''}
          activeSerialKeyExpirationDate={
            props.subscription.serialKeyExpirationDate
              ? new Date(props.subscription.serialKeyExpirationDate)
              : undefined
          }
          expiredSerialKeys={props.subscription.expiredSerialKeys ?? []}
          productName={props.subscription.product?.name ?? ''}
          onClose={() => setIsSerialKeyDialogOpen(false)}
        ></SerialKeysDialog>
      )}
    </article>
  );
};
