import {
  Spinner,
  MessageBar,
  MessageBarType,
  Dialog,
  DialogFooter,
  DialogType,
  DefaultButton,
  PrimaryButton,
} from '@fluentui/react';
import {useState} from 'react';
import commonStyles from '../../common/commonStyles.module.scss';
import {Product} from '../../Swagger/api';
import {getApis} from '../../webservices';

interface IProductRemove {
  onDone: () => void;
  onCancel: () => void;
  product: Product;
}

export const ProductRemove = (props: IProductRemove) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onDelete = async () => {
    setLoading(true);
    setHasError(false);

    try {
      const {productClient} = await getApis();
      await productClient.removeProduct(props.product.id);
      props.onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: 'Delete product',
        type: DialogType.normal,
      }}
      onDismiss={props.onCancel}
      modalProps={{
        isBlocking: true,
      }}
      minWidth="40em"
    >
      <p>
        Deleting the product <strong>{props.product.name}</strong> will delete
        the product and its product settings. The data can not be recovered. Are
        you sure?
      </p>
      <DialogFooter>
        <section className={commonStyles.dialogMessages}>
          {loading ? (
            <Spinner
              className={commonStyles.spinner}
              label="Please wait"
              ariaLive="assertive"
              labelPosition="right"
            />
          ) : null}
          {hasError ? (
            <MessageBar
              className={commonStyles.messageBar}
              messageBarType={MessageBarType.error}
              isMultiline={true}
              dismissButtonAriaLabel="Close"
            >
              Something went wrong. Please try again or contact an
              Administrator.
            </MessageBar>
          ) : null}
        </section>
        <PrimaryButton onClick={onDelete} text="Delete" />
        <DefaultButton onClick={props.onCancel} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};
