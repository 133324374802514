import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  TextField,
} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import commonStyles from '../../common/commonStyles.module.scss';
import {Client, Product, ProductSetting} from '../../Swagger/api';
import {getApis} from '../../webservices';

interface IProductSettingAdd {
  onDone: () => void;
  onCancel: () => void;
  client: Client;
  products: Product[];
  existingSettings: ProductSetting[];
}

export const ProductSettingAdd = (props: IProductSettingAdd) => {
  const [productsDropdown, setProductsDropdown] = useState<IDropdownOption[]>(
    []
  );
  const [selectedProduct, setSelectedProduct] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const options = props.products.map((x) => {
      return {
        disabled: !!props.existingSettings.filter((y) => y.productId === x.id)
          .length,
        key: x.id || -1,
        text: x.name || '',
      };
    });
    setProductsDropdown(options);
  }, [props.products, props.existingSettings]);

  const onSave = async () => {
    if (selectedProduct === -1 || !props.client.id) {
      return;
    }

    setLoading(true);
    setHasError(false);

    const item: ProductSetting = {
      clientId: props.client.id,
      configuration: JSON.stringify({clientId: '', tenant: ''}),
      productId: selectedProduct,
    };

    try {
      const {productSettingClient} = await getApis();
      await productSettingClient.addProductSetting(item);
      props.onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Panel
      isOpen={true}
      type={PanelType.smallFixedFar}
      onDismiss={() => props.onCancel()}
      headerText="Add settings for product"
      closeButtonAriaLabel="Close"
      onRenderFooterContent={() => {
        return (
          <React.Fragment>
            <section className={commonStyles.panelButtons}>
              <PrimaryButton
                className={commonStyles.primary}
                onClick={onSave}
                disabled={loading}
              >
                Save
              </PrimaryButton>
              <DefaultButton onClick={props.onCancel}>Cancel</DefaultButton>
            </section>

            <section className={commonStyles.panelMessages}>
              {loading ? (
                <Spinner
                  label="Please wait"
                  ariaLive="assertive"
                  labelPosition="right"
                />
              ) : null}
              {hasError ? (
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={true}
                  dismissButtonAriaLabel="Close"
                >
                  Something went wrong. Please try again or contact an
                  Administrator.
                </MessageBar>
              ) : null}
            </section>
          </React.Fragment>
        );
      }}
    >
      <TextField
        label="Company name"
        disabled
        value={props.client.companyName ?? ''}
      />
      <Dropdown
        label="Product"
        options={productsDropdown}
        onChange={(_event, selectedOption) =>
          setSelectedProduct(selectedOption ? Number(selectedOption.key) : -1)
        }
      />
    </Panel>
  );
};
