import {
  CheckboxVisibility,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogFooter,
  IColumn,
  Icon,
  TextField,
} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {formatDateGerman} from '../../common/dateFormatting';
import {SerialKeyDto} from '../../Swagger/api';
import styles from './subscriptions.module.scss';

interface ISerialKeysDialogProps {
  expiredSerialKeys: SerialKeyDto[];
  activeSerialKey: string;
  activeSerialKeyExpirationDate?: Date;
  productName: string;
  onClose: () => void;
}

interface ISerialKeysListStructure {
  serialKey: string;
  serialKeyExpirationDate: string;
  isValid: boolean;
}

function getExpirationDateStringFromSerialKey(
  expirationDate: Date | undefined | null
) {
  return expirationDate
    ? formatDateGerman(expirationDate)
    : 'No expiration date';
}

function onRenderItemColumn(
  item: ISerialKeysListStructure,
  _?: number,
  column?: IColumn
) {
  if (column?.fieldName === 'serialKey') {
    return <TextField readOnly value={item.serialKey} />;
  }
  if (column?.fieldName === 'isValid') {
    if (item.isValid) {
      return (
        <Icon
          iconName="CheckMark"
          aria-label="The serial key is valid"
          className={styles.isValidIcon}
        />
      );
    }
    return (
      <Icon
        iconName="Cancel"
        aria-label="The serial key is not valid"
        className={styles.isNotValidIcon}
      />
    );
  }
  return item.serialKeyExpirationDate;
}

const columns = [
  {
    key: 'serialKey',
    name: 'Serial key',
    fieldName: 'serialKey',
    className: styles.serialKeyColumn,
    ariaLabel: 'Column for serial keys',
  },
  {
    key: 'serialKeyExpirationDate',
    name: 'Expiration date',
    fieldName: 'serialKeyExpirationDate',
    className: styles.dateColumn,
    ariaLabel: 'Column for expiration dates',
    headerClassName: styles.centerHeader,
  },
  {
    key: 'isValid',
    name: 'Valid key',
    fieldName: 'isValid',
    className: styles.isValidColumn,
    ariaLabel: 'Column display if a serial key is valid',
    headerClassName: styles.centerHeader,
  },
] as IColumn[];

export default function SerialKeysDialog(props: ISerialKeysDialogProps) {
  const [serialKeys, setSerialKeys] = useState<ISerialKeysListStructure[]>([]);

  useEffect(() => {
    const currentSerialKey = {
      serialKey: props.activeSerialKey,
      serialKeyExpirationDate: getExpirationDateStringFromSerialKey(
        props.activeSerialKeyExpirationDate
      ),
      isValid: true,
    } as ISerialKeysListStructure;
    const expiredSerialKeys = props.expiredSerialKeys.map(
      (serialKey) =>
        ({
          serialKey: serialKey.key,
          serialKeyExpirationDate: getExpirationDateStringFromSerialKey(
            serialKey.serialKeyExpirationDate
          ),
          isValid: false,
        } as ISerialKeysListStructure)
    );
    setSerialKeys([currentSerialKey, ...expiredSerialKeys]);
  }, []);

  return (
    <Dialog
      onDismiss={props.onClose}
      modalProps={{isBlocking: true}}
      title={`Serial keys for ${props.productName}`}
      hidden={false}
      minWidth="min(100%, 60vw)"
    >
      <section>
        <p>
          This is a list of all the Serial Keys issued for this subscription:
        </p>
        <p>
          <strong>
            The most top key is the current key while they others are expired.
          </strong>
        </p>
        <DetailsList
          columns={columns}
          items={serialKeys}
          checkboxVisibility={CheckboxVisibility.hidden}
          onRenderItemColumn={onRenderItemColumn}
        ></DetailsList>
      </section>
      <DialogFooter>
        <DefaultButton onClick={props.onClose}>Close</DefaultButton>
      </DialogFooter>
    </Dialog>
  );
}
