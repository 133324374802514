import {MessageBar, MessageBarType, CommandBar} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {Client, Product, ProductSetting} from '../../Swagger/api';
import {getApis} from '../../webservices';
import {SmartPermissionsOutlook} from './products/smartPermissionsOutlook';
import {UnsupportedProduct} from './products/unsupportedProduct';
import {ProductSettingAdd} from './productSettingAdd';

interface IProductSetting {
  match: {
    params: {
      clientId: string;
    };
  };
}

export const ProductSettings = (props: IProductSetting) => {
  const [productSettings, setProductSettings] = useState<ProductSetting[]>([]);
  const [productSettingAddPanelOpen, setProductSettingAddPanelOpen] =
    useState(false);
  const [loadedClient, setLoadedClient] = useState<Client>();
  const [loadedProducts, setLoadedProducts] = useState<Product[]>([]);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    loadProductSettings();
    loadClient();
    loadProducts();
  }, []);

  const loadProductSettings = async () => {
    try {
      const {productSettingClient} = await getApis();
      const items: ProductSetting[] =
        await productSettingClient.getProductSettings(
          Number(props.match.params.clientId)
        );
      setProductSettings(items);
    } catch {
      setError(true);
    }
  };

  const loadClient = async () => {
    try {
      const {clientClient} = await getApis();
      const loadedClients: Client[] = await clientClient.getClients();
      for (const client of loadedClients) {
        if (client.id === Number(props.match.params.clientId)) {
          setLoadedClient(client);
        }
      }
    } catch {
      setError(true);
    }
  };

  const loadProducts = async () => {
    try {
      const {productClient} = await getApis();
      const loadedProductsArray: Product[] = await productClient.getProducts();
      setLoadedProducts(loadedProductsArray);
    } catch {
      setError(true);
    }
  };

  return (
    <React.Fragment>
      <h1>Manage Product Settings</h1>
      <CommandBar
        items={[
          {
            iconProps: {iconName: 'CircleAddition'},
            key: 'add product setting',
            onClick: () => setProductSettingAddPanelOpen(true),
            text: 'Add a new product setting',
          },
        ]}
      />
      {hasError ? (
        <MessageBar messageBarType={MessageBarType.error}>
          Could not load product settings.
        </MessageBar>
      ) : null}
      {productSettings.map((p) => {
        if (
          p.product &&
          p.product.productId &&
          '{6333980E-4E49-4320-8B91-6F9B02D3CDC1}'
            .toLowerCase()
            .indexOf(p.product.productId.toLowerCase()) !== -1
        ) {
          return (
            <SmartPermissionsOutlook
              key={p.productId}
              settings={p}
              onDone={() => loadProductSettings()}
            />
          );
        }

        return (
          <UnsupportedProduct
            key={p.productId}
            settings={p}
            onDone={() => loadProductSettings()}
          />
        );
      })}
      {productSettingAddPanelOpen && loadedClient && loadProducts ? (
        <ProductSettingAdd
          onCancel={() => setProductSettingAddPanelOpen(false)}
          client={loadedClient}
          products={loadedProducts}
          onDone={() => {
            loadProductSettings();
            setProductSettingAddPanelOpen(false);
          }}
          existingSettings={productSettings}
        />
      ) : null}
    </React.Fragment>
  );
};
