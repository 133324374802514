import {
  CommandBar,
  DetailsList,
  MessageBar,
  MessageBarType,
  SelectionMode,
} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {formatDateGerman} from '../../../common/dateFormatting';
import {ProductVersion} from '../../../Swagger/api';
import {getApis} from '../../../webservices';
import {ProductVersionAdd} from './productVersionAdd';
import {ProductVersionEdit} from './productVersionEdit';
import {ProductVersionRemove} from './productVersionRemove';

interface IProductVersions {
  match: {
    params: {
      productId: number;
    };
  };
}

export const ProductVersions = (props: IProductVersions) => {
  const [hasError, setHasError] = useState(false);
  const [versions, setVersions] = useState<ProductVersion[]>([]);
  const [addPanelOpen, setAddPanelOpen] = useState(false);
  const [editPanelOpen, setEditPanelOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [selectedRelease, setSelectedRelease] = useState<ProductVersion>();

  useEffect(() => {
    loadProductVersions();
  }, []);

  const loadProductVersions = async () => {
    try {
      const {productClient} = await getApis();
      const items = await productClient.getProductVersions(
        props.match.params.productId
      );
      setVersions(items);
    } catch {
      setHasError(true);
    }
  };

  return (
    <React.Fragment>
      <h1>Manage Product Versions</h1>
      {hasError ? (
        <MessageBar messageBarType={MessageBarType.error}>
          Could not load product versions.
        </MessageBar>
      ) : null}
      <CommandBar
        items={[
          {
            iconProps: {iconName: 'CircleAddition'},
            key: 'add product',
            onClick: () => setAddPanelOpen(true),
            text: 'Add a new released version',
          },
          {
            disabled: !selectedRelease,
            iconProps: {iconName: 'ProductRelease'},
            key: 'edit product',
            onClick: () => setEditPanelOpen(true),
            text: 'Edit release',
          },
          {
            disabled: !selectedRelease,
            iconProps: {iconName: 'Delete'},
            key: 'delete product',
            onClick: () => setRemoveDialogOpen(true),
            text: 'Delete release',
          },
        ]}
      />
      <DetailsList
        columns={[
          {
            key: 'name',
            name: 'Version Name',
            fieldName: 'version',
            minWidth: 100,
            maxWidth: 150,
          },
          {
            key: 'release date',
            name: 'Release date',
            fieldName: 'releaseDisplay',
            minWidth: 200,
          },
        ]}
        items={versions.map((x) => {
          return {
            id: x.id,
            key: x.id,
            release: x.release,
            releaseDisplay: formatDateGerman(x.release!),
            version: x.version,
          };
        })}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick={true}
        onActiveItemChanged={(item) => {
          setSelectedRelease(item);
        }}
      />
      {addPanelOpen ? (
        <ProductVersionAdd
          onCancel={() => setAddPanelOpen(false)}
          onDone={() => {
            setAddPanelOpen(false);
            loadProductVersions();
          }}
          productId={props.match.params.productId}
        />
      ) : null}
      {editPanelOpen && selectedRelease ? (
        <ProductVersionEdit
          onCancel={() => setEditPanelOpen(false)}
          onDone={() => {
            setEditPanelOpen(false);
            loadProductVersions();
          }}
          productVersion={selectedRelease}
        />
      ) : null}
      {removeDialogOpen && selectedRelease ? (
        <ProductVersionRemove
          onCancel={() => setRemoveDialogOpen(false)}
          onDone={() => {
            setRemoveDialogOpen(false);
            loadProductVersions();
          }}
          productVersionId={selectedRelease.id!}
        />
      ) : null}
    </React.Fragment>
  );
};
