import {MessageBar, MessageBarType} from '@fluentui/react';

export default function EmptyState() {
  return (
    <MessageBar
      messageBarType={MessageBarType.info}
      isMultiline={true}
      dismissButtonAriaLabel="Close"
    >
      No items are available, please choose different filters or refiners.
    </MessageBar>
  );
}
