import {
  Spinner,
  TextField,
  Panel,
  PanelType,
  MessageBar,
  MessageBarType,
  Checkbox,
  DefaultButton,
  PrimaryButton,
  Label,
} from '@fluentui/react';
import React, {useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import commonStyles from '../../common/commonStyles.module.scss';
import {Product, SubscriptionType} from '../../Swagger/api';
import {getApis} from '../../webservices';
import styles from './products.module.scss';

interface IProductAdd {
  onDone: () => void;
  onCancel: () => void;
}

export const ProductAdd = (props: IProductAdd) => {
  const [name, setName] = useState('');
  const [allowedSubscriptionTypes, setAllowedSubscriptionTypes] =
    useState<SubscriptionType>(0);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onSave = async () => {
    setLoading(true);
    setHasError(false);

    const item: Product = {
      allowedSubscriptionTypes,
      name,
      productId: uuidv4(),
      id: 0,
    };

    try {
      const {productClient} = await getApis();
      await productClient.addProduct(item);
      props.onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  const setAllowedType = (value: SubscriptionType, toAdd: boolean) => {
    let copy = allowedSubscriptionTypes;

    if (toAdd) {
      copy += value;
    } else {
      copy -= value;
    }

    setAllowedSubscriptionTypes(copy);
  };
  return (
    <Panel
      isOpen={true}
      type={PanelType.smallFixedFar}
      onDismiss={() => props.onCancel()}
      headerText="Add a new product"
      closeButtonAriaLabel="Close"
      onRenderFooterContent={() => {
        return (
          <React.Fragment>
            <section className={commonStyles.panelButtons}>
              <PrimaryButton
                className={commonStyles.primary}
                onClick={onSave}
                disabled={loading}
              >
                Save
              </PrimaryButton>
              <DefaultButton onClick={props.onCancel}>Cancel</DefaultButton>
            </section>

            <section className={commonStyles.panelMessages}>
              {loading ? (
                <Spinner
                  label="Please wait"
                  ariaLive="assertive"
                  labelPosition="right"
                />
              ) : null}
              {hasError ? (
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={true}
                  dismissButtonAriaLabel="Close"
                >
                  Something went wrong. Please try again or contact an
                  Administrator.
                </MessageBar>
              ) : null}
            </section>
          </React.Fragment>
        );
      }}
    >
      <TextField
        label="Product name"
        value={name}
        onChange={(_event, newValue) => setName(newValue || '')}
      />

      <section className={styles.boxes}>
        <Label>Allowed subscription types</Label>
        <Checkbox
          label="Demo"
          className={styles.checkbox}
          onChange={(_event, checked) =>
            setAllowedType(SubscriptionType.Demo, checked as boolean)
          }
        />
        <Checkbox
          label="Per User"
          className={styles.checkbox}
          onChange={(_event, checked) =>
            setAllowedType(SubscriptionType.PerUser, checked as boolean)
          }
        />
        <Checkbox
          label="Flatrate"
          className={styles.checkbox}
          onChange={(_event, checked) =>
            setAllowedType(SubscriptionType.FlatRate, checked as boolean)
          }
        />
        <Checkbox
          label="Per User SharePoint"
          className={styles.checkbox}
          onChange={(_event, checked) =>
            setAllowedType(
              SubscriptionType.PerUserSharePoint,
              checked as boolean
            )
          }
        />
        <Checkbox
          label="Flatrate SharePoint"
          className={styles.checkbox}
          onChange={(_event, checked) =>
            setAllowedType(
              SubscriptionType.FlatrateSharePoint,
              checked as boolean
            )
          }
        />
        <Checkbox
          label="Software Assurance"
          className={styles.checkbox}
          onChange={(_event, checked) =>
            setAllowedType(
              SubscriptionType.SoftwareAssurance,
              checked as boolean
            )
          }
        />
      </section>
    </Panel>
  );
};
