import {
  Spinner,
  MessageBar,
  MessageBarType,
  Dialog,
  DialogFooter,
  DialogType,
  ActionButton,
  DefaultButton,
  IconButton,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import commonStyles from '../../../common/commonStyles.module.scss';
import {Subscription, SubscriptionDomain} from '../../../Swagger/api';
import {getApis} from '../../../webservices';
import styles from './manageDomains.module.scss';

interface IManageDomains {
  onDone: () => void;
  onCancel: () => void;
  subscription: Subscription;
}

export const ManageDomains = (props: IManageDomains) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [domains, setDomains] = useState<SubscriptionDomain[]>([]);

  useEffect(() => {
    if (props.subscription.domains) {
      setDomains(props.subscription.domains);
    }
  }, [props.subscription.domains]);

  const addEmptyEntry = () => {
    const copy = [...domains];
    copy.push({domain: '', id: uuidv4() as any, subscriptionId: 0});
    setDomains(copy);
  };

  const removeEntry = (id: number) => {
    const copy = [...domains];
    setDomains(copy.filter((x) => x.id !== id));
  };

  const setEntry = (id: number, value: string) => {
    const copy = [...domains].map((x) => {
      if (x.id === id) {
        x.domain = value;
      }
      return x;
    });
    setDomains(copy);
  };

  const save = async () => {
    setLoading(true);
    setHasError(false);

    try {
      const {subscriptionClient} = await getApis();
      await subscriptionClient.updateDomains(
        props.subscription.id,
        domains.filter((x) => x.domain).map((x) => x.domain!)
      );
      props.onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: 'Manage domains',
        type: DialogType.normal,
      }}
      modalProps={{
        isBlocking: true,
      }}
      minWidth="40em"
      onDismiss={props.onCancel}
    >
      {domains.length ? (
        <section className={styles.items}>
          {domains.map((d) => (
            <article key={d.id} className={styles.item}>
              <TextField
                className={styles.text}
                value={d.domain ?? ''}
                onChange={(_event, value) => setEntry(d.id!, value || '')}
              />
              <IconButton
                iconProps={{iconName: 'Delete'}}
                onClick={() => removeEntry(d.id!)}
              />
            </article>
          ))}
        </section>
      ) : (
        <MessageBar>No domains configured</MessageBar>
      )}
      <DialogFooter>
        <section className={commonStyles.dialogMessages}>
          {loading ? (
            <Spinner
              className={commonStyles.spinnerCenter}
              label="Please wait"
              ariaLive="assertive"
              labelPosition="right"
            />
          ) : null}
          {hasError ? (
            <MessageBar
              className={commonStyles.messageBar}
              messageBarType={MessageBarType.error}
              isMultiline={true}
              dismissButtonAriaLabel="Close"
            >
              Something went wrong. Please try again or contact an
              Administrator.
            </MessageBar>
          ) : null}
        </section>
        <section className={commonStyles.dialogButtons}>
          <article className={commonStyles.leftSegment}>
            <ActionButton
              iconProps={{iconName: 'CircleAddition'}}
              onClick={addEmptyEntry}
            >
              Add new domain
            </ActionButton>
          </article>
          <article className={commonStyles.rightSegment}>
            <PrimaryButton onClick={save} text="Update" />
            <DefaultButton onClick={props.onCancel} text="Cancel" />
          </article>
        </section>
      </DialogFooter>
    </Dialog>
  );
};
