import {
  Spinner,
  MessageBar,
  MessageBarType,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogType,
  DefaultButton,
  PrimaryButton,
} from '@fluentui/react';
import {useState} from 'react';
import commonStyles from '../../common/commonStyles.module.scss';
import {Subscription} from '../../Swagger/api';
import {getApis} from '../../webservices';

interface IResetSubscription {
  onDone: () => void;
  onCancel: () => void;
  subscription: Subscription;
}

export const ResetSubscription = (props: IResetSubscription) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onDelete = async () => {
    setLoading(true);
    setHasError(false);

    try {
      const {subscriptionClient} = await getApis();
      await subscriptionClient.resetSubscription(props.subscription.id);
      props.onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Dialog
      hidden={false}
      modalProps={{
        isBlocking: true,
        styles: {},
      }}
      minWidth="40em"
      onDismiss={props.onCancel}
    >
      <DialogContent
        subText="Resetting the subscription will remove all registered users from the subscriptions. The data can not be recovered. Are you sure?"
        title="Reset subscription"
        type={DialogType.normal}
      />
      <DialogFooter>
        <section className={commonStyles.dialogMessages}>
          {loading ? (
            <Spinner
              className={commonStyles.spinner}
              label="Please wait"
              ariaLive="assertive"
              labelPosition="right"
            />
          ) : null}
          {hasError ? (
            <MessageBar
              className={commonStyles.messageBar}
              messageBarType={MessageBarType.error}
              isMultiline={true}
              dismissButtonAriaLabel="Close"
            >
              Something went wrong. Please try again or contact an
              Administrator.
            </MessageBar>
          ) : null}
        </section>
        <PrimaryButton onClick={onDelete} text="Reset" />
        <DefaultButton onClick={props.onCancel} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};
