import {DefaultButton, Icon, TextField} from '@fluentui/react';
import {useState} from 'react';
import {formatDateGerman} from '../../common/dateFormatting';
import {Subscription} from '../../Swagger/api';
import styles from './subscriptions.module.scss';
import {CancelSubscription} from './cancelSubscription';
import {ManageDomains} from './manageDomains/manageDomains';
import {RegisteredUsers} from './registeredUsers/registeredUsers';
import {ResetSubscription} from './resetSubscription';

interface IDemoSubscription {
  subscription: Subscription;
  onReload: () => void;
}

export const DemoSubscription = (props: IDemoSubscription) => {
  const [subscriptionManageDomainsOpen, setSubscriptionManageDomainsOpen] =
    useState(false);
  const [subscriptionRegisteredUserOpen, setSubscriptionRegisteredUserOpen] =
    useState(false);
  const [resetSubscriptionOpen, setResetSubscriptionOpen] = useState(false);
  const [removeSubscriptionOpen, setRemoveSubscriptionOpen] = useState(false);

  return (
    <article
      className={`${styles.subscriptionContainer} demo subscription-item`}
    >
      <section className={styles.title}>
        <strong>
          {props.subscription.product ? props.subscription.product.name : ''}
        </strong>{' '}
        - Demo subscription
        <Icon className={styles.icon} iconName="Timer" />
      </section>
      <section className={styles.container}>
        <TextField
          label="Date started"
          readOnly
          value={
            props.subscription.start
              ? formatDateGerman(props.subscription.start)
              : ''
          }
        />
        <TextField
          label="Users registered"
          readOnly
          value={
            props.subscription.registeredUsers
              ? props.subscription.registeredUsers.length.toString()
              : 'N/A'
          }
        />
      </section>
      <section className={styles.container}>
        <TextField
          label="Expiration date"
          readOnly
          value={
            props.subscription.expiration
              ? formatDateGerman(props.subscription.expiration)
              : ''
          }
        />
        <TextField
          label="Remaining permissions"
          readOnly
          value={
            props.subscription.remainingDemoPermissions
              ? props.subscription.remainingDemoPermissions.toString()
              : 'N/A'
          }
        />
      </section>
      <section className={styles.buttonContainer}>
        <article className={`${styles.leftContainer} ${styles.buttons}`}>
          <DefaultButton onClick={() => setSubscriptionManageDomainsOpen(true)}>
            Manage domains
          </DefaultButton>
          <DefaultButton
            onClick={() => setSubscriptionRegisteredUserOpen(true)}
          >
            Registered users
          </DefaultButton>
          <DefaultButton onClick={() => setResetSubscriptionOpen(true)}>
            Reset subscription
          </DefaultButton>
        </article>
        <article className={`${styles.rightContainer} ${styles.buttons}`}>
          <DefaultButton
            className={styles.remove}
            onClick={() => setRemoveSubscriptionOpen(true)}
          >
            Cancel subscription
          </DefaultButton>
        </article>
      </section>

      {subscriptionManageDomainsOpen ? (
        <ManageDomains
          onCancel={() => setSubscriptionManageDomainsOpen(false)}
          onDone={() => {
            setSubscriptionManageDomainsOpen(false);
            props.onReload();
          }}
          subscription={props.subscription}
        />
      ) : null}
      {subscriptionRegisteredUserOpen ? (
        <RegisteredUsers
          subscription={props.subscription}
          onDone={() => {
            props.onReload();
            setSubscriptionRegisteredUserOpen(false);
          }}
          onReload={props.onReload}
        />
      ) : null}
      {removeSubscriptionOpen ? (
        <CancelSubscription
          onCancel={() => setRemoveSubscriptionOpen(false)}
          onDone={() => {
            setRemoveSubscriptionOpen(false);
            props.onReload();
          }}
          subscription={props.subscription}
        />
      ) : null}
      {resetSubscriptionOpen ? (
        <ResetSubscription
          onCancel={() => setResetSubscriptionOpen(false)}
          onDone={() => {
            setResetSubscriptionOpen(false);
            props.onReload();
          }}
          subscription={props.subscription}
        />
      ) : null}
    </article>
  );
};
