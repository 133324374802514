import {
  Spinner,
  MessageBar,
  MessageBarType,
  Dialog,
  DialogFooter,
  DialogType,
  DatePicker,
  DefaultButton,
  PrimaryButton,
} from '@fluentui/react';
import {useEffect, useState} from 'react';
import commonStyles from '../../common/commonStyles.module.scss';
import {getTimezoneDate} from '../../common/dateFormatting';
import {Subscription} from '../../Swagger/api';
import {getApis} from '../../webservices';

interface IExtendSubscription {
  onDone: () => void;
  onCancel: () => void;
  subscription: Subscription;
}

export const ExtendSubscription = (props: IExtendSubscription) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [expiration, setExpiration] = useState<Date>();

  useEffect(() => {
    setExpiration(
      props.subscription.expiration
        ? new Date(props.subscription.expiration.toString())
        : new Date()
    );
  }, []);

  const onChangeMaxUsers = async () => {
    setLoading(true);
    setHasError(false);

    try {
      const {subscriptionClient} = await getApis();
      await subscriptionClient.extendSubscription(
        props.subscription.id,
        expiration ? getTimezoneDate(expiration) : undefined
      );
      props.onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        subText: 'Please set the expiration date of the subscription:',
        title: 'Extend subscription',
        type: DialogType.normal,
      }}
      modalProps={{
        isBlocking: true,
      }}
      minWidth="40em"
      onDismiss={props.onCancel}
    >
      <DatePicker
        label="Expiration date"
        placeholder="Select a date..."
        allowTextInput={true}
        value={expiration!}
        onSelectDate={(newValue) => setExpiration(newValue || new Date())}
        ariaLabel="Select a date"
      />
      <DialogFooter>
        <section className={commonStyles.dialogMessages}>
          {loading ? (
            <Spinner
              className={commonStyles.spinner}
              label="Please wait"
              ariaLive="assertive"
              labelPosition="right"
            />
          ) : null}
          {hasError ? (
            <MessageBar
              className={commonStyles.messageBar}
              messageBarType={MessageBarType.error}
              isMultiline={true}
              dismissButtonAriaLabel="Close"
            >
              Something went wrong. Please try again or contact an
              Administrator.
            </MessageBar>
          ) : null}
        </section>
        <PrimaryButton onClick={onChangeMaxUsers} text="Extend" />
        <DefaultButton onClick={props.onCancel} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};
