import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  TextField,
} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {ProductSetting} from '../../../Swagger/api';
import {getApis} from '../../../webservices';
import styles from '../../subscriptions/subscriptions.module.scss';
import {ProductSettingRemove} from '../productSettingRemove';

interface ISmartPermissionsOutlook {
  onDone: () => void;
  settings: ProductSetting;
}

export const SmartPermissionsOutlook = (props: ISmartPermissionsOutlook) => {
  const [clientId, setClientId] = useState('');
  const [tenant, setTenantId] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [productRemovePanelOpen, setProductRemovePanelOpen] = useState(false);

  useEffect(() => {
    if (props.settings.configuration) {
      const configuration: {clientId: string; tenant: string} = JSON.parse(
        props.settings.configuration
      );

      setClientId(configuration.clientId);
      setTenantId(configuration.tenant);
    }
  }, [props.settings.configuration]);

  const onSave = async () => {
    setLoading(true);
    setHasError(false);

    const item: ProductSetting = {
      ...props.settings,
      configuration: JSON.stringify({clientId, tenant}),
    };

    try {
      const {productSettingClient} = await getApis();
      await productSettingClient.updateProductSetting(item);
      props.onDone();
      setLoading(false);
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <article className={`${styles.subscriptionContainer} subscription-item`}>
      <section className={styles.title}>
        <strong>Smart Permissions for Outlook</strong>
      </section>
      <section className={styles.container}>
        <TextField
          label="Client ID"
          value={clientId}
          onChange={(_event, value) => setClientId(value || '')}
        />
      </section>
      <section className={styles.container}>
        <TextField
          label="Tenant"
          value={tenant}
          onChange={(_event, value) => {
            setTenantId(value || '');
          }}
        />
      </section>
      <section className={styles.buttonContainer}>
        <article className={`${styles.leftContainer} ${styles.buttons}`}>
          <DefaultButton onClick={onSave} disabled={loading}>
            Save settings
          </DefaultButton>
        </article>
        <article className={`${styles.rightContainer} ${styles.buttons}`}>
          <DefaultButton
            className={styles.remove}
            onClick={() => setProductRemovePanelOpen(true)}
          >
            Delete settings
          </DefaultButton>
        </article>
      </section>

      {hasError ? (
        <section className={styles.error}>
          <MessageBar messageBarType={MessageBarType.error}>
            Could not save product settings.
          </MessageBar>
        </section>
      ) : null}

      {productRemovePanelOpen ? (
        <ProductSettingRemove
          onCancel={() => setProductRemovePanelOpen(false)}
          productSetting={props.settings}
          onDone={() => {
            setProductRemovePanelOpen(false);
            props.onDone();
          }}
        />
      ) : null}
    </article>
  );
};
