import {Dialog} from '@fluentui/react';

interface ILicenseDialog {
  onClose: () => void;
}

export const LicenseDialog = (props: ILicenseDialog) => {
  return (
    <Dialog
      onDismiss={props.onClose}
      modalProps={{isBlocking: false}}
      title="Setup the automated offline registry entries"
      hidden={false}
      minWidth="60em"
    >
      <section>
        <p>
          Smart Permissions für Outlook will automatically look for a serial key
          saved in Windows Registry. The key must be saved like that:
        </p>
        <p>
          Path:
          HKEY_CURRENT_USER\Software\Microsoft\Office\Outlook\Addins\HanseVision.SmartPermissions.Settings
        </p>
        <p>Name: SerialKey</p>
      </section>
    </Dialog>
  );
};
