import {
  Checkbox,
  DatePicker,
  DayOfWeek,
  DefaultButton,
  Dropdown,
  FontSizes,
  FontWeights,
  IDropdown,
  IDropdownOption,
  Label,
  Stack,
} from '@fluentui/react';
import {useId} from '@fluentui/react-hooks';
import {useEffect, useRef, useState} from 'react';
import {formatDateGerman} from '../../../common/dateFormatting';
import TFilterType from '../../../models/filterTypes';
import {Client, FilterViewModel} from '../../../Swagger/api';

interface IAuditFilter {
  clients: Client[];
  filter: FilterViewModel;
  handleChange: (
    field: TFilterType,
    newValue: Date | number[] | boolean
  ) => void;
  handleReset: () => void;
}

export default function AuditFilter(props: IAuditFilter) {
  const [selectedClients, setSelectedClients] = useState<number[]>([]);
  const clientPickerId = useId('clientPicker');
  const customerPickerRef = useRef<IDropdown>(null);

  useEffect(() => {
    setSelectedClients(props.filter.clients ?? []);
  }, [props.filter.clients]);

  function transformClientsToDropdownOption() {
    const {clients} = props;
    return clients.map((client) => ({
      key: client.id,
      text: client.companyName,
    })) as IDropdownOption[];
  }

  const handleDropdownSelection = (
    _: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    if (item) {
      const newSelectedClients = item.selected
        ? [...selectedClients, Number(item.key)]
        : selectedClients.filter((key) => key !== item.key);
      setSelectedClients(newSelectedClients);
      props.handleChange('clients', newSelectedClients);
    }
  };

  function openCustomerPicker() {
    customerPickerRef.current?.focus(true);
  }

  return (
    <Stack horizontal className="audits-filter-container">
      <Stack tokens={{childrenGap: '1rem'}} grow>
        <Stack>
          <Label
            styles={{
              root: {
                fontSize: FontSizes.size16,
                fontWeight: FontWeights.bold,
              },
            }}
          >
            Activities filter
          </Label>
          <Stack horizontal tokens={{childrenGap: '1rem'}}>
            <Checkbox
              label="Creation of items (e. g. clients, products etc.)"
              checked={props.filter.create}
              onChange={(_, checked?) =>
                props.handleChange('create', !!checked)
              }
              className="checkbox_create"
            />
            <Checkbox
              label="Modification of items (e. g. domains etc.)"
              checked={props.filter.modify}
              onChange={(_, checked?) =>
                props.handleChange('modify', !!checked)
              }
              className="checkbox_update"
            />
            <Checkbox
              label="Deletion of items (e. g. clients, products etc.)"
              checked={props.filter.delete}
              onChange={(_, checked?) =>
                props.handleChange('delete', !!checked)
              }
              className="checkbox_delete"
            />
          </Stack>
        </Stack>
        <Stack>
          <Label
            styles={{
              root: {
                fontSize: FontSizes.size16,
                fontWeight: FontWeights.bold,
              },
            }}
          >
            Refiners
          </Label>
          <Stack horizontal tokens={{childrenGap: '1rem'}}>
            <DatePicker
              label="From"
              allowTextInput
              placeholder="Select starting date"
              ariaLabel="Select starting date"
              firstDayOfWeek={DayOfWeek.Monday}
              formatDate={(date?) => (date ? formatDateGerman(date) : '')}
              value={props.filter?.from as Date}
              onSelectDate={(date) => props.handleChange('from', date!)}
              className="earliest-date-picker"
            />

            <DatePicker
              label="To"
              allowTextInput
              placeholder="Select latest date"
              ariaLabel="Select latest date"
              firstDayOfWeek={DayOfWeek.Monday}
              formatDate={(date?) => (date ? formatDateGerman(date) : '')}
              value={props.filter?.to as Date}
              onSelectDate={(date) => props.handleChange('to', date!)}
              className="latest-date-picker"
            />
            <Stack.Item grow>
              <Dropdown
                className="client-picker"
                placeholder="Choose clients"
                ariaLabel="Choose clients"
                multiSelect
                options={transformClientsToDropdownOption()}
                selectedKeys={selectedClients}
                onChange={handleDropdownSelection}
                componentRef={customerPickerRef}
                id={clientPickerId}
                onRenderLabel={() => (
                  <Label htmlFor={clientPickerId} onClick={openCustomerPicker}>
                    Choose one or more clients
                  </Label>
                )}
              />
            </Stack.Item>
          </Stack>
        </Stack>
      </Stack>
      <Stack.Item>
        <DefaultButton onClick={props.handleReset}>Reset filter</DefaultButton>
      </Stack.Item>
    </Stack>
  );
}
