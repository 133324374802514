import {InteractionType} from '@azure/msal-browser';
import {MsalAuthenticationTemplate, MsalProvider} from '@azure/msal-react';
import {initializeIcons} from '@fluentui/font-icons-mdl2';
import {ThemeProvider} from '@fluentui/react';
import './app.scss';
import {Layout} from './components/layout/layout';
import {msalInstance} from './webservices';

initializeIcons();

export default function App() {
  // disable auth for testing via cypress
  if (process.env.REACT_APP_IS_TEST_ENV) {
    return (
      <ThemeProvider>
        <Layout />
      </ThemeProvider>
    );
  }

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <ThemeProvider>
          <Layout />
        </ThemeProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}
