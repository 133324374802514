import {
  DetailsList,
  MessageBar,
  MessageBarType,
  SelectionMode,
  CommandBar,
} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {Product, SubscriptionType} from '../../Swagger/api';
import {getApis} from '../../webservices';
import {history} from '../../routing/routes';
import {ProductAdd} from './productAdd';
import {ProductEdit} from './productEdit';
import {ProductRemove} from './productRemove';

export const Products = () => {
  // States
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [productAddPanelOpen, setProductAddPanelOpen] = useState(false);
  const [productEditPanelOpen, setProductEditPanelOpen] = useState(false);
  const [productRemovePanelOpen, setProductRemovePanelOpen] = useState(false);
  const [hasError, setError] = useState(false);
  const emptyProduct: Product = {
    id: 0,
    productId: '',
    allowedSubscriptionTypes: SubscriptionType.Demo,
  };

  useEffect(() => {
    loadProducts();
  }, []);

  const loadProducts = async () => {
    try {
      const {productClient} = await getApis();
      const items: Product[] = await productClient.getProducts();
      setProducts(items);
    } catch {
      setError(true);
    }
  };

  // Output
  return (
    <React.Fragment>
      <h1>Manage Products</h1>
      <CommandBar
        items={[
          {
            iconProps: {iconName: 'CircleAddition'},
            key: 'add product',
            onClick: () => setProductAddPanelOpen(true),
            text: 'Add a new product',
          },
          {
            disabled: !selectedProduct,
            iconProps: {iconName: 'ProductRelease'},
            key: 'edit product',
            onClick: () => setProductEditPanelOpen(true),
            text: 'Edit product',
          },
          {
            disabled: !selectedProduct,
            iconProps: {iconName: 'Delete'},
            key: 'delete product',
            onClick: () => setProductRemovePanelOpen(true),
            text: 'Delete product',
          },
          {
            disabled: !selectedProduct,
            iconProps: {iconName: 'OEM'},
            key: 'versions',
            onClick: () =>
              history.push(`/product/versions/${selectedProduct!.id}`),
            text: 'Manage versions',
          },
        ]}
      />
      {hasError ? (
        <MessageBar messageBarType={MessageBarType.error}>
          Could not load products.
        </MessageBar>
      ) : null}
      <DetailsList
        columns={[
          {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 300,
            maxWidth: 400,
          },
          {
            key: 'productId',
            name: 'Product ID',
            fieldName: 'productId',
            minWidth: 100,
          },
        ]}
        items={products.map((x) => {
          return {
            allowedSubscriptionTypes: x.allowedSubscriptionTypes,
            id: x.id,
            key: x.id,
            name: x.name,
            productId: x.productId,
          };
        })}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick={true}
        onActiveItemChanged={(item) => {
          setSelectedProduct(item);
        }}
      />
      {productAddPanelOpen ? (
        <ProductAdd
          onCancel={() => setProductAddPanelOpen(false)}
          onDone={() => {
            loadProducts();
            setProductAddPanelOpen(false);
          }}
        />
      ) : null}
      {productEditPanelOpen ? (
        <ProductEdit
          onCancel={() => setProductEditPanelOpen(false)}
          product={selectedProduct || emptyProduct}
          onDone={() => {
            loadProducts();
            setProductEditPanelOpen(false);
          }}
        />
      ) : null}
      {productRemovePanelOpen ? (
        <ProductRemove
          onCancel={() => setProductRemovePanelOpen(false)}
          product={selectedProduct || emptyProduct}
          onDone={() => {
            loadProducts();
            setProductRemovePanelOpen(false);
          }}
        />
      ) : null}
    </React.Fragment>
  );
};
