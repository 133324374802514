import {createBrowserHistory} from 'history';

export const history = createBrowserHistory();
export const paths = {
  audit: {
    root: '/audits',
  },
  clients: {
    root: '/clients',
  },
  home: {
    root: '/',
  },
  products: {
    root: '/products',
    versions: '/product/versions/:productId',
  },
  productsSettings: {
    root: '/productSettings/:clientId',
  },
  subscriptions: {
    root: '/subscriptions/:clientId',
  },
};
