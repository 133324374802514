import {ActionButton, MessageBar, MessageBarType, Nav} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import {
  msalInstance,
  handleRedirectLogin,
  handleRedirectLogout,
} from '../../webservices';
import Audit from '../audits/audits';
import {Clients} from '../clients/clients';
import {Products} from '../products/products';
import {ProductVersions} from '../products/productVersions/productVersions';
import {ProductSettings} from '../productSettings/productSettings';
import {Subscriptions} from '../subscriptions/subscriptions';
import styles from './layout.module.scss';
import {history, paths} from '../../routing/routes';
import {AccountInfo} from '@azure/msal-browser';

export function Layout() {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  // disable auth for testing via cypress
  const [account] = process.env.REACT_APP_IS_TEST_ENV
    ? ([{username: 'test_user_admin', name: 'test_name'}] as AccountInfo[])
    : msalInstance.getAllAccounts();

  useEffect(() => {
    // disable auth for testing via cypress
    if (process.env.REACT_APP_IS_TEST_ENV) {
      setIsAdmin(true);
      return;
    }
    const roles = (account?.idTokenClaims as any)?.roles as string[];
    if (roles && roles.length) {
      setIsAdmin(!!roles.find((role) => role === 'Admin'));
    }
  }, [account?.idTokenClaims]);

  return (
    <React.Fragment>
      <header className={styles.header}>
        <img
          className={styles.logo}
          src="/HanseVision_logo.png"
          title="HanseVision"
          alt="Logo of HanseVision GmbH"
        />
        <div>
          <div className={styles.appTitle}>License Manager</div>
          <div className={styles.appDescription}>for Smart Products</div>
        </div>
        {account?.username && (
          <div className={styles.userData}>
            {account?.username}
            {' | '}
            <ActionButton
              className={styles.logOutLink}
              onClick={async () => {
                sessionStorage.clear();
                await handleRedirectLogout();
              }}
            >
              Log out
            </ActionButton>
          </div>
        )}

        {!account?.name && (
          <div className={styles.userData}>
            <ActionButton
              className={styles.logOutLink}
              onClick={async () => {
                sessionStorage.clear();
                await handleRedirectLogin();
              }}
            >
              Log in
            </ActionButton>
          </div>
        )}
      </header>
      <main className={styles.main}>
        <section className={styles.side}>
          <Nav
            groups={[
              {
                links: [
                  {
                    icon: 'People',
                    key: paths.clients.root,
                    name: 'Clients',
                    onClick: () => history.push(paths.clients.root),
                    url: '',
                  },
                  {
                    icon: 'Product',
                    key: paths.products.root,
                    name: 'Products',
                    onClick: () => history.push(paths.products.root),
                    url: '',
                  },
                  {
                    icon: 'ComplianceAudit',
                    key: paths.audit.root,
                    name: 'Audits',
                    onClick: () => history.push(paths.audit.root),
                    url: '',
                  },
                ],
              },
            ]}
          />
        </section>
        {isAdmin ? (
          <section className={styles.content}>
            <Router history={history}>
              <Switch>
                <Route exact path={paths.audit.root} component={Audit} />
                <Route exact path={paths.home.root} component={Clients} />
                <Route exact path={paths.clients.root} component={Clients} />
                <Route exact path={paths.products.root} component={Products} />
                <Route
                  exact
                  path={paths.products.versions}
                  component={ProductVersions}
                />
                <Route
                  exact
                  path={paths.subscriptions.root}
                  component={Subscriptions}
                />
                <Route
                  exact
                  path={paths.productsSettings.root}
                  component={ProductSettings}
                />
              </Switch>
            </Router>
          </section>
        ) : (
          <section className={styles.content}>
            <MessageBar
              messageBarType={MessageBarType.error}
              className={styles.unauthorized}
              isMultiline={true}
              dismissButtonAriaLabel="Close"
            >
              You don't currently have permission to access this site.
            </MessageBar>
          </section>
        )}
      </main>
    </React.Fragment>
  );
}
