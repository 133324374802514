import {
  Spinner,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  DefaultButton,
  PrimaryButton,
} from '@fluentui/react';
import {useState} from 'react';
import commonStyles from '../../../common/commonStyles.module.scss';
import {getApis} from '../../../webservices';

interface IProductVersionRemove {
  onDone: () => void;
  onCancel: () => void;
  productVersionId: number;
}

export const ProductVersionRemove = (props: IProductVersionRemove) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onDelete = async () => {
    setLoading(true);
    setHasError(false);

    try {
      const {productClient} = await getApis();
      await productClient.removeProductVersion(props.productVersionId);
      props.onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: 'Delete product version release',
        type: DialogType.normal,
      }}
      modalProps={{
        isBlocking: true,
      }}
      minWidth="40em"
      onDismiss={props.onCancel}
    >
      <p>The data can not be recovered. Are you sure?</p>
      <DialogFooter>
        <section className={commonStyles.dialogMessages}>
          {loading ? (
            <Spinner
              className={commonStyles.spinner}
              label="Please wait"
              ariaLive="assertive"
              labelPosition="right"
            />
          ) : null}
          {hasError ? (
            <MessageBar
              className={commonStyles.messageBar}
              messageBarType={MessageBarType.error}
              isMultiline={true}
              dismissButtonAriaLabel="Close"
            >
              Something went wrong. Please try again or contact an
              Administrator.
            </MessageBar>
          ) : null}
        </section>
        <PrimaryButton onClick={onDelete} text="Delete" />
        <DefaultButton onClick={props.onCancel} text="Cancel" />
      </DialogFooter>
    </Dialog>
  );
};
