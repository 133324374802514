import {
  TextField,
  Spinner,
  Panel,
  PanelType,
  MessageBar,
  MessageBarType,
  DefaultButton,
  PrimaryButton,
} from '@fluentui/react';
import React, {useState} from 'react';
import commonStyles from '../../common/commonStyles.module.scss';
import {Client} from '../../Swagger/api';
import {getApis} from '../../webservices';

interface IClientAdd {
  onDone: () => void;
  onCancel: () => void;
}

export const ClientAdd = (props: IClientAdd) => {
  const [companyName, setCompanyName] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onSave = async () => {
    setLoading(true);
    setHasError(false);

    const item: Client = {
      companyName,
      id: 0,
    };

    try {
      const {clientClient} = await getApis();
      await clientClient.addClient(item);
      props.onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Panel
      isOpen={true}
      type={PanelType.smallFixedFar}
      onDismiss={() => props.onCancel()}
      headerText="Add a new client"
      closeButtonAriaLabel="Close"
      onRenderFooterContent={() => {
        return (
          <React.Fragment>
            <section className={commonStyles.panelButtons}>
              <PrimaryButton
                className={commonStyles.primary}
                onClick={onSave}
                disabled={loading}
              >
                Save
              </PrimaryButton>
              <DefaultButton onClick={props.onCancel}>Cancel</DefaultButton>
            </section>

            <section className={commonStyles.panelMessages}>
              {loading ? (
                <Spinner
                  label="Please wait"
                  ariaLive="assertive"
                  labelPosition="right"
                />
              ) : null}
              {hasError ? (
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={true}
                  dismissButtonAriaLabel="Close"
                >
                  Something went wrong. Please try again or contact an
                  Administrator.
                </MessageBar>
              ) : null}
            </section>
          </React.Fragment>
        );
      }}
    >
      <TextField
        label="Client name"
        value={companyName}
        onChange={(_event, newValue) => setCompanyName(newValue || '')}
      />
    </Panel>
  );
};
