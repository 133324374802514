import {MessageBar, MessageBarType} from '@fluentui/react';

export default function ErrorState() {
  return (
    <MessageBar
      messageBarType={MessageBarType.error}
      isMultiline={true}
      dismissButtonAriaLabel="Close"
    >
      Something went wrong. Please try again or contact an Administrator.
    </MessageBar>
  );
}
