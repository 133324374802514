export const copyTextToClipboard = (value: string) => {
  let result = false;
  const copyTextarea = document.createElement('textarea');
  copyTextarea.style.position = 'fixed';
  copyTextarea.style.top = '0';
  copyTextarea.style.left = '0';
  copyTextarea.style.width = '2em';
  copyTextarea.style.height = '2em';
  copyTextarea.style.padding = '0';
  copyTextarea.style.border = 'none';
  copyTextarea.style.outline = 'none';
  copyTextarea.style.boxShadow = 'none';
  copyTextarea.style.background = 'transparent';
  copyTextarea.value = value;
  document.body.appendChild(copyTextarea);
  copyTextarea.select();
  try {
    const successful = document.execCommand('copy');
    if (successful) {
      result = true;
    } else {
      result = false;
    }
  } catch (err) {
    result = false;
  }
  document.body.removeChild(copyTextarea);
  return result;
};
