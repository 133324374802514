import AuditFilter from './filter/auditsFilter';
import AuditList from './list/auditsList';
import {useState, useEffect} from 'react';
import {AuditViewModel, Client, FilterViewModel} from '../../Swagger/api';
import {getApis} from '../../webservices';
import TFilterType from '../../models/filterTypes';

function removeTimeZoneDataFromDate(date: Date) {
  // cut time zone information to prevent bugs in the api
  if (date) {
    return `${date.getFullYear()}-${date.toLocaleDateString('de-DE', {
      month: '2-digit',
    })}-${date.toLocaleDateString('de-DE', {
      day: '2-digit',
    })}` as any;
  }
}

export default function Audit() {
  const [audits, setAudits] = useState<AuditViewModel[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [isLoadComplete, setIsLoadComplete] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [filter, setFilter] = useState<FilterViewModel>({
    create: true,
    modify: true,
    delete: true,
    to: null,
    from: null,
    clients: [],
  });

  useEffect(() => {
    fetchData();
  }, [filter]);

  async function fetchData() {
    try {
      setIsLoadComplete(false);
      setHasError(false);
      const {auditClient, clientClient} = await getApis();
      const copiedFilter = {...filter};
      copiedFilter.from =
        copiedFilter?.from && removeTimeZoneDataFromDate(copiedFilter.from);
      copiedFilter.to =
        copiedFilter?.to && removeTimeZoneDataFromDate(copiedFilter?.to);

      const auditResponse = await auditClient.getAudits(copiedFilter);
      setAudits(auditResponse);

      if (!clients.length) {
        var clientResponse = await clientClient.getClients();
        setClients(clientResponse);
      }
    } catch (error) {
      console.error(error);
      setHasError(true);
    }
    setIsLoadComplete(true);
  }

  function resetFilter() {
    setFilter({
      create: true,
      modify: true,
      delete: true,
      to: null,
      from: null,
      clients: [],
    });
  }

  function handleChangeFilter(
    field: TFilterType,
    newValue: Date | number[] | boolean
  ) {
    // prevent unnecessary reload at opening and closing the date-picker
    if (field !== 'clients' && filter[field] === newValue) {
      return;
    }

    const newFilter = {
      ...filter,
    };
    newFilter[field] = newValue as any;
    setFilter(newFilter);
  }

  return (
    <>
      <h1>Audits</h1>
      <div>
        <AuditFilter
          clients={clients}
          filter={filter}
          handleChange={handleChangeFilter}
          handleReset={resetFilter}
        ></AuditFilter>
        <AuditList
          audits={audits}
          hasError={hasError}
          isLoadComplete={isLoadComplete}
        ></AuditList>
      </div>
    </>
  );
}
