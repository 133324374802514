import {
  CheckboxVisibility,
  DetailsList,
  IColumn,
  SelectionMode,
} from '@fluentui/react';
import {formatDateTimeGerman} from '../../../common/dateFormatting';
import {ActivitiesEnum, AuditViewModel, TypeEnum} from '../../../Swagger/api';
import EmptyState from '../states/EmptyState';
import ErrorState from '../states/ErrorState';
import LoadState from '../states/LoadState';
import styles from './auditsList.module.scss';

interface IAuditList {
  audits: AuditViewModel[];
  isLoadComplete: boolean;
  hasError: boolean;
  showClientsColumn?: boolean;
}

const columns = [
  {
    key: 'activity',
    name: 'Activity',
    fieldName: 'activity',
    ariaLabel: 'Column for activities',
  },
  {
    key: 'entity',
    name: 'Client',
    fieldName: 'entity',
    ariaLabel: 'Column for clients',
    minWidth: 250,
    maxWidth: 250,
  },
  {
    key: 'modifiedOn',
    name: 'Date',
    fieldName: 'modifiedOn',
    ariaLabel: 'Column for modification date',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'modifiedBy',
    name: 'Modified by',
    fieldName: 'modifiedBy',
    ariaLabel: 'Column for users who made the modification',
    minWidth: 200,
    maxWidth: 200,
  },
] as IColumn[];

/**
 * Takes an item and calculates the contents of a cell.
 * Each cell receives a title-attribute and the name of entities are highlighted
 *
 * @param item a audit view model
 * @returns a jsx element with highlighted entities
 */
function getActivityFieldContent(item: AuditViewModel) {
  const typeStringDictionary = {
    [TypeEnum.Client]: 'client',
    [TypeEnum.Product]: 'product',
    [TypeEnum.Subscription]: 'subscription',
  };

  let title = '';
  let cellContent = <span></span>;

  switch (item.activity) {
    case ActivitiesEnum.UpdateDomains:
      title = 'Modified domains for a subscription';
      cellContent = <span>{title}</span>;
      break;
    case ActivitiesEnum.UpdateMaxUsers:
      title = 'Modified number of maximal users for a subscription';
      cellContent = <span>{title}</span>;
      break;
    case ActivitiesEnum.UpdateProductVersion:
      cellContent = (
        <span>
          Modified a product version for the product{' '}
          <span className={styles.entityField}>{item.entity}</span>
        </span>
      );
      break;
    case ActivitiesEnum.Reset:
      title = 'Reset a subscription';
      cellContent = <span>{title}</span>;
      break;
    case ActivitiesEnum.AddProductVersion:
      title = `Added a new product version to the product ${item.entity}`;
      cellContent = (
        <span>
          Added a new product version to the product{' '}
          <span className={styles.entityField}>{item.entity}</span>
        </span>
      );
      break;
    case ActivitiesEnum.RemoveProductVersion:
      title = `Deleted a product version from the product ${item.entity}`;
      cellContent = (
        <span>
          Deleted a product version from the product{' '}
          <span className={styles.entityField}>{item.entity}</span>
        </span>
      );
      break;
    case ActivitiesEnum.Extend:
      title = `Extended a subscription`;
      cellContent = <span>{title}</span>;
      break;
    case ActivitiesEnum.Create:
      title = `Added a new ${typeStringDictionary[item.type]} ${
        item.type === TypeEnum.Subscription ? `for ${item.entity}` : ''
      }`;
      cellContent = (
        <span>
          Added a new {typeStringDictionary[item.type]}{' '}
          {item.type === TypeEnum.Subscription ? (
            <span>
              for client{' '}
              <span className={styles.entityField}>{item.entity}</span>
            </span>
          ) : (
            <span className={styles.entityField}>{item.entity}</span>
          )}
        </span>
      );
      break;
    case ActivitiesEnum.Update:
      title = `Updated the ${typeStringDictionary[item.type]} ${item.entity}`;
      cellContent = (
        <span>
          Updated the {typeStringDictionary[item.type]}{' '}
          <span className={styles.entityField}>{item.entity}</span>
        </span>
      );
      break;
    case ActivitiesEnum.Delete:
      title = `Deleted a ${typeStringDictionary[item.type]}`;
      cellContent = <span>Deleted a {typeStringDictionary[item.type]}</span>;
      break;
  }
  return <span title={title}>{cellContent}</span>;
}

export default function AuditList(props: IAuditList) {
  /**
   * Calculates the content of a column based on the passed item.
   *
   * @param item a audit view model
   * @param index current index of the item
   * @param column the current column
   * @returns either a formatted (date-)string or cell
   */
  function renderCells(
    item?: AuditViewModel,
    index?: number,
    column?: IColumn
  ) {
    if (!column || !item) {
      return;
    }

    const {fieldName} = column;
    column.key = `${fieldName}-${index}`;
    switch (fieldName) {
      case 'activity':
        return getActivityFieldContent(item);
      case 'entity':
        const isClientDisplayed =
          item.type === TypeEnum.Client || item.type === TypeEnum.Subscription;
        return isClientDisplayed ? item.entity : '';
      case 'modifiedOn':
        return formatDateTimeGerman(item.modifiedOn);
      case 'modifiedBy':
        return item.modifiedBy;
      default:
        return '';
    }
  }

  if (!props.isLoadComplete) {
    return <LoadState />;
  }

  if (props.hasError) {
    return <ErrorState />;
  }

  if (!props.audits.length) {
    return <EmptyState />;
  }

  return (
    <DetailsList
      columns={
        props.showClientsColumn ?? true
          ? columns
          : columns.filter((column) => column.key !== 'entity')
      }
      items={props.audits}
      checkboxVisibility={CheckboxVisibility.hidden}
      selectionMode={SelectionMode.none}
      onRenderItemColumn={renderCells}
      className="audits-list-container"
    ></DetailsList>
  );
}
