import {
  TextField,
  Spinner,
  Panel,
  PanelType,
  MessageBar,
  MessageBarType,
  DefaultButton,
  PrimaryButton,
  DatePicker,
  DayOfWeek,
} from '@fluentui/react';
import React, {useState} from 'react';
import commonStyles from '../../../common/commonStyles.module.scss';
import {getTimezoneDate} from '../../../common/dateFormatting';
import {ProductVersion} from '../../../Swagger/api';
import {getApis} from '../../../webservices';

interface IProductVersionAdd {
  onDone: () => void;
  onCancel: () => void;
  productId: number;
}

export const ProductVersionAdd = (props: IProductVersionAdd) => {
  const [version, setVersion] = useState('0.0.0');
  const [release, setRelease] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onSave = async () => {
    setLoading(true);
    setHasError(false);

    const item: ProductVersion = {
      productId: props.productId,
      release: getTimezoneDate(release),
      version,
      id: 0,
    };

    try {
      const {productClient} = await getApis();
      await productClient.addProductVersion(item);
      props.onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Panel
      isOpen={true}
      type={PanelType.smallFixedFar}
      onDismiss={() => props.onCancel()}
      headerText="Add a new version release"
      closeButtonAriaLabel="Close"
      onRenderFooterContent={() => {
        return (
          <React.Fragment>
            <section className={commonStyles.panelButtons}>
              <PrimaryButton
                className={commonStyles.primary}
                onClick={onSave}
                disabled={loading}
              >
                Save
              </PrimaryButton>
              <DefaultButton onClick={props.onCancel}>Cancel</DefaultButton>
            </section>

            <section className={commonStyles.panelMessages}>
              {loading ? (
                <Spinner
                  label="Please wait"
                  ariaLive="assertive"
                  labelPosition="right"
                />
              ) : null}
              {hasError ? (
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={true}
                  dismissButtonAriaLabel="Close"
                >
                  Something went wrong. Please try again or contact an
                  administrator.
                </MessageBar>
              ) : null}
            </section>
          </React.Fragment>
        );
      }}
    >
      <TextField
        label="Version name"
        value={version}
        onChange={(_event, newValue) => setVersion(newValue || '')}
      />
      <DatePicker
        label="Release date"
        firstDayOfWeek={DayOfWeek.Monday}
        placeholder="Select a date..."
        value={release}
        onSelectDate={(newValue) => setRelease(newValue || new Date())}
        ariaLabel="Select a date"
      />
    </Panel>
  );
};
