import {MessageBar, MessageBarType, CommandBar} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {SubscriptionType, SubscriptionViewModel} from '../../Swagger/api';
import {getApis} from '../../webservices';
import AuditDialog from '../audits/dialog/auditsDialog';
import {DemoSubscription} from './demoSubscription';
import {FlatrateSubscription} from './flatrateSubscription';
import {FlatrateSubscriptionSharePoint} from './flatrateSubscriptionSharePoint';
import {PerUserSubscription} from './perUserSubscription';
import {PerUserSubscriptionSharePoint} from './perUserSubscriptionSharePoint';
import {SoftwareAssuranceSubscription} from './softwareAssuranceSubscription';
import SubscriptionAdd from './subscriptionAdd';
import styles from './subscriptions.module.scss';

interface ISubscriptions {
  match: {
    params: {
      clientId: string;
    };
  };
}
export const Subscriptions = (props: ISubscriptions) => {
  const [subscriptions, setSubscriptions] = useState<SubscriptionViewModel[]>(
    []
  );
  const [isSubscriptionAddOpen, setIsSubscriptionAddOpen] = useState(false);
  const [isAuditDialogOpen, setIsAuditDialogOpen] = useState(false);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    loadSubscriptions();
  }, []);

  const loadSubscriptions = async () => {
    try {
      const {subscriptionClient} = await getApis();
      const items: SubscriptionViewModel[] =
        await subscriptionClient.getSubscription(
          Number(props.match.params.clientId)
        );
      setSubscriptions(items);
    } catch {
      setError(true);
    }
  };

  return (
    <React.Fragment>
      <h1>Subscription Details</h1>
      <CommandBar
        items={[
          {
            iconProps: {iconName: 'AddFriend'},
            key: 'add a subscription',
            onClick: () => setIsSubscriptionAddOpen(true),
            text: 'Add a new subscription',
          },
          {
            iconProps: {iconName: 'ComplianceAudit'},
            key: 'view audits',
            onClick: () => setIsAuditDialogOpen(true),
            text: 'View Audits',
          },
        ]}
      />

      {hasError ? (
        <MessageBar messageBarType={MessageBarType.error}>
          Could not load subscriptions.
        </MessageBar>
      ) : (
        <React.Fragment>
          {subscriptions.map((s) => {
            if (s.type === SubscriptionType.Demo) {
              return (
                <DemoSubscription
                  key={s.id}
                  subscription={s}
                  onReload={() => loadSubscriptions()}
                />
              );
            }

            if (s.type === SubscriptionType.PerUser) {
              return (
                <PerUserSubscription
                  key={s.id}
                  subscription={s}
                  onReload={() => loadSubscriptions()}
                />
              );
            }

            if (s.type === SubscriptionType.FlatRate) {
              return (
                <FlatrateSubscription
                  key={s.id}
                  subscription={s}
                  onReload={() => loadSubscriptions()}
                />
              );
            }

            if (s.type === SubscriptionType.PerUserSharePoint) {
              return (
                <PerUserSubscriptionSharePoint
                  key={s.id}
                  subscription={s}
                  onReload={() => loadSubscriptions()}
                />
              );
            }

            if (s.type === SubscriptionType.FlatrateSharePoint) {
              return (
                <FlatrateSubscriptionSharePoint
                  key={s.id}
                  subscription={s}
                  onReload={() => loadSubscriptions()}
                />
              );
            }

            if (s.type === SubscriptionType.SoftwareAssurance) {
              return (
                <SoftwareAssuranceSubscription
                  key={s.id}
                  subscription={s}
                  onReload={() => loadSubscriptions()}
                />
              );
            }

            return (
              <MessageBar className={styles.unknownTypeBar} key={-1}>
                Unknown type
              </MessageBar>
            );
          })}
        </React.Fragment>
      )}

      {isSubscriptionAddOpen && (
        <SubscriptionAdd
          onCancel={() => setIsSubscriptionAddOpen(false)}
          onDone={() => {
            loadSubscriptions();
            setIsSubscriptionAddOpen(false);
          }}
          clientId={Number(props.match.params.clientId)}
        />
      )}

      {isAuditDialogOpen && (
        <AuditDialog
          onClose={() => setIsAuditDialogOpen(false)}
          clientId={Number(props.match.params.clientId)}
        ></AuditDialog>
      )}
    </React.Fragment>
  );
};
