import {DefaultButton, Icon, Callout, TextField, Stack} from '@fluentui/react';
import React, {useState} from 'react';
import {copyTextToClipboard} from '../../common/copyText';
import {formatDateGerman} from '../../common/dateFormatting';
import {SubscriptionViewModel} from '../../Swagger/api';
import styles from './subscriptions.module.scss';
import {CancelSubscription} from './cancelSubscription';
import {ManageDomains} from './manageDomains/manageDomains';

interface IFlatrateSubscriptionSharePoint {
  subscription: SubscriptionViewModel;
  onReload: () => void;
}

export const FlatrateSubscriptionSharePoint = (
  props: IFlatrateSubscriptionSharePoint
) => {
  const [subscriptionManageDomainsOpen, setSubscriptionManageDomainsOpen] =
    useState(false);
  const [removeSubscriptionOpen, setRemoveSubscriptionOpen] = useState(false);
  const [copySuccessfulOpen, setCopySuccessfulOpen] = useState(false);
  const [copyFailedOpen, setCopyFailedOpen] = useState(false);

  const copyToClipboard = async () => {
    if (copyTextToClipboard(props.subscription.serialKey || '')) {
      setCopySuccessfulOpen(true);
    } else {
      setCopyFailedOpen(true);
    }
  };
  const _buttonELement = React.createRef<HTMLDivElement>();

  return (
    <article
      className={`${styles.subscriptionContainer} flatrate-sp subscription-item`}
    >
      <section className={styles.title}>
        <strong>
          {props.subscription.product ? props.subscription.product.name : ''}
        </strong>{' '}
        - Flatrate subscription (SharePoint)
        <Icon className={styles.icon} iconName="Airplane" />
      </section>
      <section className={styles.container}>
        <TextField
          label="Date started"
          readOnly
          value={
            props.subscription.start
              ? formatDateGerman(props.subscription.start)
              : ''
          }
        />
      </section>
      <section className={styles.container}>
        <Stack horizontal tokens={{childrenGap: 16}}>
          <TextField
            label="Serial key"
            readOnly
            value={props.subscription.serialKey || '0'}
            styles={{root: {flexGrow: 1}}}
          />
          <div className={styles.copy} ref={_buttonELement}>
            <DefaultButton onClick={() => copyToClipboard()}>
              Copy
            </DefaultButton>
          </div>
        </Stack>
        <TextField
          label="Serial key expiration"
          readOnly
          value={
            props.subscription.serialKeyExpirationDate
              ? formatDateGerman(props.subscription.serialKeyExpirationDate)
              : ''
          }
        />
      </section>
      {copyFailedOpen ? (
        <section className={styles.container}>
          <Callout
            role="alertdialog"
            gapSpace={0}
            target={_buttonELement}
            setInitialFocus={true}
            hidden={false}
            onDismiss={() => setCopyFailedOpen(false)}
          >
            <div className={styles.callout}>
              Could not copy the serial key to the clipboard. Please try another
              browser.
            </div>
          </Callout>
        </section>
      ) : null}
      {copySuccessfulOpen ? (
        <section className={styles.container}>
          <Callout
            role="alertdialog"
            gapSpace={0}
            target={_buttonELement}
            setInitialFocus={true}
            hidden={false}
            onDismiss={() => setCopySuccessfulOpen(false)}
          >
            <div className={styles.callout}>
              Successfully copied the serial key to the clipboard.
            </div>
          </Callout>
        </section>
      ) : null}
      <section className={styles.buttonContainer}>
        <article className={`${styles.leftContainer} ${styles.buttons}`}>
          <DefaultButton onClick={() => setSubscriptionManageDomainsOpen(true)}>
            Manage domains
          </DefaultButton>
        </article>
        <article className={`${styles.rightContainer} ${styles.buttons}`}>
          <DefaultButton
            className={styles.remove}
            onClick={() => setRemoveSubscriptionOpen(true)}
          >
            Cancel subscription
          </DefaultButton>
        </article>
      </section>
      {subscriptionManageDomainsOpen ? (
        <ManageDomains
          onCancel={() => setSubscriptionManageDomainsOpen(false)}
          onDone={() => {
            setSubscriptionManageDomainsOpen(false);
            props.onReload();
          }}
          subscription={props.subscription}
        />
      ) : null}
      {removeSubscriptionOpen ? (
        <CancelSubscription
          onCancel={() => setRemoveSubscriptionOpen(false)}
          onDone={() => {
            setRemoveSubscriptionOpen(false);
            props.onReload();
          }}
          subscription={props.subscription}
        />
      ) : null}
    </article>
  );
};
