import {
  Spinner,
  TextField,
  Panel,
  PanelType,
  MessageBar,
  MessageBarType,
  Checkbox,
  DefaultButton,
  PrimaryButton,
  Label,
} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import commonStyles from '../../common/commonStyles.module.scss';
import {Product, SubscriptionType} from '../../Swagger/api';
import {getApis} from '../../webservices';
import styles from './products.module.scss';

interface IProductEdit {
  onDone: () => void;
  onCancel: () => void;
  product: Product;
}

export const ProductEdit = (props: IProductEdit) => {
  const [name, setName] = useState('');
  const [allowedSubscriptionTypes, setAllowedSubscriptionTypes] =
    useState<SubscriptionType>(0);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setName(props.product.name || '');
    setAllowedSubscriptionTypes(props.product.allowedSubscriptionTypes || 0);
  }, [props.product.allowedSubscriptionTypes, props.product.name]);

  const isAllowedType = (value: SubscriptionType) => {
    if (!allowedSubscriptionTypes) {
      return false;
    }

    // tslint:disable-next-line: no-bitwise
    return (value & allowedSubscriptionTypes) !== 0;
  };

  const setAllowedType = (value: SubscriptionType, toAdd: boolean) => {
    let copy = allowedSubscriptionTypes;

    if (toAdd) {
      copy += value;
    } else {
      copy -= value;
    }

    setAllowedSubscriptionTypes(copy);
  };

  const onSave = async () => {
    setLoading(true);
    setHasError(false);

    const item: Product = {
      allowedSubscriptionTypes,
      id: props.product.id,
      name,
      productId: props.product.productId,
    };

    try {
      const {productClient} = await getApis();
      await productClient.updateProduct(item);
      props.onDone();
    } catch {
      setHasError(true);
      setLoading(false);
    }
  };

  return (
    <Panel
      isOpen={true}
      type={PanelType.smallFixedFar}
      onDismiss={() => props.onCancel()}
      headerText="Edit product"
      closeButtonAriaLabel="Close"
      onRenderFooterContent={() => {
        return (
          <React.Fragment>
            <section className={commonStyles.panelButtons}>
              <PrimaryButton
                className={commonStyles.primary}
                onClick={onSave}
                disabled={loading}
              >
                Save
              </PrimaryButton>
              <DefaultButton onClick={props.onCancel}>Cancel</DefaultButton>
            </section>

            <section className={commonStyles.panelMessages}>
              {loading ? (
                <Spinner
                  label="Please wait"
                  ariaLive="assertive"
                  labelPosition="right"
                />
              ) : null}
              {hasError ? (
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={true}
                  dismissButtonAriaLabel="Close"
                >
                  Something went wrong. Please try again or contact an
                  Administrator.
                </MessageBar>
              ) : null}
            </section>
          </React.Fragment>
        );
      }}
    >
      <TextField
        label="Product name"
        value={name}
        onChange={(_event, newValue) => setName(newValue || '')}
      />
      <TextField label="Product ID" disabled value={props.product.productId} />

      <section className={styles.boxes}>
        <Label>Allowed subscription types</Label>
        <Checkbox
          label="Demo"
          className={styles.checkbox}
          checked={isAllowedType(SubscriptionType.Demo)}
          onChange={(_event, checked) =>
            setAllowedType(SubscriptionType.Demo, checked as boolean)
          }
        />
        <Checkbox
          label="Per User"
          className={styles.checkbox}
          checked={isAllowedType(SubscriptionType.PerUser)}
          onChange={(_event, checked) =>
            setAllowedType(SubscriptionType.PerUser, checked as boolean)
          }
        />
        <Checkbox
          label="Flatrate"
          className={styles.checkbox}
          checked={isAllowedType(SubscriptionType.FlatRate)}
          onChange={(_event, checked) =>
            setAllowedType(SubscriptionType.FlatRate, checked as boolean)
          }
        />
        <Checkbox
          label="Per User SharePoint"
          className={styles.checkbox}
          checked={isAllowedType(SubscriptionType.PerUserSharePoint)}
          onChange={(_event, checked) =>
            setAllowedType(
              SubscriptionType.PerUserSharePoint,
              checked as boolean
            )
          }
        />
        <Checkbox
          label="Flatrate SharePoint"
          className={styles.checkbox}
          checked={isAllowedType(SubscriptionType.FlatrateSharePoint)}
          onChange={(_event, checked) =>
            setAllowedType(
              SubscriptionType.FlatrateSharePoint,
              checked as boolean
            )
          }
        />
        <Checkbox
          label="Software Assurance"
          className={styles.checkbox}
          checked={isAllowedType(SubscriptionType.SoftwareAssurance)}
          onChange={(_event, checked) =>
            setAllowedType(
              SubscriptionType.SoftwareAssurance,
              checked as boolean
            )
          }
        />
      </section>
    </Panel>
  );
};
